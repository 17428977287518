import React, { useState, useEffect } from "react";    // useLayoutEffect, 
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from 'axios';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Badge } from "react-bootstrap";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
);

// import { Brand } from "../../_seebizsol/layout/components/brand/Brand";
const Dashboard = ({ auth: { user }, isAuthenticated }) => {
  const [userStatsCount, setuserStatsCount] = useState(0);
  const [userList, setuserList] = useState([]);
  const [expStats, setExpStats] = useState([]);
  // const [stats, setStats] = useState({});

  const [orders, setOrders] = useState([]);
  const [orderCount, setOrderCount] = useState(0);

  const [signUpData, setSignUpData] = useState([])

  const [latestProduct, setLatestProduct] = useState([]);
  const [productGraph, setProductGraph] = useState([]);

  const [vendorList, setvendorList] = useState({ purchased: [], manual: [], commission: [], expire: [], canceled: [], refunded: [], paymentFailed: [] });

  useEffect(() => {
    async function getLatestProduct() {
      try {
        const res = await axios.get('/api/product/getLatestProduct')
        const products = res.data.data
        // console.log('products :>> ', res.data);
        setLatestProduct(products)

        var bothValues = []
        bothValues.push(res.data.publishedCount);
        bothValues.push(res.data.draftCount);
        setProductGraph(bothValues)

      } catch (err) {
        console.log('getLatestProduct func Err ', err.message);
      }
    }
    getLatestProduct();

    async function getTodaysOrder() {
      try {
        const res = await axios.get('/api/productOrder/getTodaysOrder')
        const order = res.data.payload
        // console.log('order :>> ', order);
        setOrders(order.ordersList)
        setOrderCount(order.ordersListCount)

      } catch (err) {
        console.log('getTodaysOrder func Err ', err.message);
      }
    }
    getTodaysOrder();

    async function getLatestVendors() {
      try {
        const res = await axios.get('/api/users/latest_vendors')

        const { purchasedUsers, manualUsers, commissionUsers, canceledUsers } = res.data;    // expireUsers, refundedUsers, paymentFailedUsers

        // const vendors = res.data.result

        // var paidUsers = vendors[0].paidUsers
        // var expireUsers = vendors[0].expireUsers
        // var canceledUsers = vendors[0].canceledUsers
        // var refundedUsers = vendors[0].refundedUsers
        // var paymentFailedUsers = vendors[0].paymentFailedUsers

        setvendorList({
          purchased: purchasedUsers, manual: manualUsers, canceled: canceledUsers, commission: commissionUsers       //  expire: expireUsers, refunded: refundedUsers, paymentFailed: paymentFailedUsers
        })
        // // console.log('order :>> ', order);
        // setOrders(order.ordersList)
        // setOrderCount(order.ordersListCount)

      } catch (err) {
        console.log('getLatestVendors func Err ', err.message);
      }
    }
    getLatestVendors();

    axios.get('/api/users/latest_signup')
      .then(res => {
        if (res.data && res.data.payload) {
          // console.log("res ", res.data.payload);

          var bothValues = []
          bothValues.push(res.data.payload.buyerCount);
          bothValues.push(res.data.payload.sellerCount);
          setSignUpData(bothValues)

          setuserStatsCount(res.data.payload.usersListCount);
          setuserList(res.data.payload.usersList);
          setExpStats(res.data.payload.expiryList)
          // setStats(res.data.payload.result);
        }
      }).catch(error => console.log(error));
  }, [])

  const productData = {
    labels: ['Published', 'Draft'],
    datasets: [
      {
        label: 'Latest Product',
        data: productGraph,
        backgroundColor: [
          'rgba(53, 162, 235, 0.5)',
          'rgba(255, 99, 132, 0.5)',
        ],
        borderColor: [
          'rgb(53, 162, 235)',
          'rgb(255, 99, 132)',
        ],
        borderWidth: 1,
      },
    ],
  }

  const uploadScript =
    user.role === "admin" || user.role === "teamlead" ? (
      <Link className="text-white" to="/uploadproduct">Upload_File</Link>
    ) : (
      <div className='text-white'>Upload_File</div>
    );
  const createUser =
    user.role === "admin" ? (
      <Link className="text-white" to="/createuser">Create_User</Link>
    ) : (
      <div className='text-white'>Create_User</div>
    );
  // const className = "gutter-b";

  // useLayoutEffect(() => {
  // }, []);

  const data = {
    labels: ['Buyer', 'Seller'],
    datasets: [
      {
        label: 'Sign Up Vendors',
        data: signUpData,
        backgroundColor: [
          'rgba(53, 162, 235, 0.5)',
          'rgb(11, 218, 81, 0.5)',
        ],
        borderColor: [
          'rgb(53, 162, 235)',
          'rgb(11, 218, 81)',
        ],
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="container">

      <div className="row mt-10">
        <div className="col-lg-4">
          <div
            className='card card-custom gutter-b card-stretch'
          >
            <div className="card-header pt-5">
              <h3 className="card-title font-weight-bolder text-Black">
                Monthly Sign up Users
              </h3>
            </div>
            {
              (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user") ?
                <div className="card-body d-flex flex-column p-0">

                  <div className="card-rounded-bottom p-3">
                    <Pie data={data} />
                  </div>

                  <div className="card-spacer bg-primary card-rounded flex-grow-1">
                    <div className="row m-0">
                      <div className="col px-8 py-6 mr-8">
                        <div className="font-size-h4 text-white font-weight-bold">
                          Buyers :
                          <span className="font-size-h4 text-dark font-weight-bolder"> {signUpData[0]}</span>
                        </div>
                        {/* <div className="font-size-h4 font-weight-bolder">{signUpData[0]}</div> */}
                      </div>
                      <div className="col px-8 py-6">
                        <div className="font-size-h4 text-white font-weight-bold">
                          Sellers :
                          <span className="font-size-h4 text-dark font-weight-bolder"> {signUpData[1]}</span>
                        </div>
                        {/* <div className="font-size-h4 font-weight-bolder">{signUpData[1]}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
            }
          </div>
        </div>
        <div className="col-lg-8">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header pt-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  <i className="fas  fa-users fa-2x text-info" ></i>
                  <span className="ml-2 pb-2"> New Sign up Users</span>
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  List Of New Sign up Users
                </span>
              </h3>
              <div className="card-toolbar">
                {(user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user") ?
                  <Link to="/recentUsersList">
                    <button type="button" className="btn btn-info ml-5" style={{ borderRadius: "10rem" }} title="Today SignUp Users Count">
                      Today's Sign up <span className="badge badge-dark">{userStatsCount}</span>
                    </button>
                  </Link> :
                  null
                }
              </div>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive">
                <table className="table table-borderless table-vertical-center">
                  <thead>
                    <tr>
                      <th className="p-0" style={{ width: "80px" }}></th>
                      <th className="p-0" style={{ minWidth: "180px" }}><span className="d-flex align-items-start">Business Name</span></th>
                      <th className="p-0" style={{ minWidth: "130px" }}><span className="">Business Type</span></th>
                      <th className="p-0" style={{ minWidth: "120px" }}><span className="">Email Verified</span></th>
                      <th className="p-0" style={{ minWidth: "110px" }}><span>Created At</span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (userList && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? userList.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val.images && val.images.profile_pic && val.images.profile_pic[100] ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val.images.profile_pic[100]})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val.email}
                            </span>
                          </td>
                          <td className="">
                            <span className="text-muted font-weight-bold">
                              {val.businessTypeName}
                            </span>
                          </td>
                          <td className="">
                            <span className="text-muted font-weight-bold">
                              {val.email_verification === true ? "True" : "False"}
                            </span>
                          </td>
                          <td className="">
                            <span className="text-muted font-weight-bold d-flex align-items-start">
                              {new Date(val.created_at).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' })}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4">
          <div
            className='card card-custom gutter-b card-stretch'
          >
            <div className="card-header pt-5">
              <h3 className="card-title font-weight-bolder text-Black">
                Monthly Vendor's Products
              </h3>
            </div>
            {
              (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user") ?
                <div className="card-body d-flex flex-column p-0">

                  <div className="card-rounded-bottom p-3">
                    <Doughnut data={productData} />
                  </div>

                  <div className="card-spacer bg-success card-rounded flex-grow-1">
                    <div className="row m-0">
                      <div className="col px-6 py-2">
                        <div className="font-size-h4 text-white font-weight-bold">
                          Published :
                          <span className="font-size-h4 text-dark font-weight-bolder"> {productGraph[0]}</span>
                        </div>
                        {/* <div className="font-size-h4 font-weight-bolder">{signUpData[0]}</div> */}
                      </div>
                      <div className="col px-4 py-2">
                        <div className="font-size-h4 text-white font-weight-bold">
                          Draft :
                          <span className="font-size-h4 text-dark font-weight-bolder"> {productGraph[1]}</span>
                        </div>
                        {/* <div className="font-size-h4 font-weight-bolder">{signUpData[1]}</div> */}
                      </div>
                    </div>
                  </div>
                </div>
                :
                null
            }
          </div>
        </div>

        <div className="col-xl-8">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header pt-3">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Latest Products
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  List of Latest Products
                </span>
              </h3>
            </div>
            {(user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user") ?
              <div className="card-body pt-5 pb-0">
                {latestProduct.map((prd, key) => {
                  return <div className="d-flex flex-wrap align-items-center mb-10" key={key}>
                    <div className="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                      {prd.product_main_image !== '' && prd.product_images.length ?
                        <div
                          className="symbol-label"
                          style={{
                            backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL}${process.env.REACT_APP_PRODUCT_IMAGE_URL}/${prd.product_images[0][250]})`,
                          }}
                        ></div>
                        :
                        prd.product_video && prd.product_video.video_image !== '' ?
                          <div
                            className="symbol-label"
                            style={{
                              backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL}${process.env.REACT_APP_PRODUCT_IMAGE_URL}/${prd.product_video.video_image})`,
                            }}
                          ></div>
                          :
                          <div
                            className="symbol-label"
                            style={{
                              backgroundImage: `url('/assets/img/default.png')`,
                            }}
                          ></div>
                      }
                    </div>

                    <div className="d-flex flex-column flex-grow-1 my-lg-0 my-2 pr-3">
                      <Link to={{ pathname: `./product-detail`, search: `?productId=${prd._id}` }} style={{ maxWidth: 500 }}
                      >
                        <span className="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">{prd.title}</span>
                      </Link>

                      <span className="text-muted font-weight-bold font-size-sm my-1">
                        {prd.sku}
                      </span>
                      <span className="text-muted font-weight-bold font-size-sm">
                        Created by:{" "}
                        {prd.merchant_id && prd.merchant_id.business_name ?
                          <Link
                            to={{ pathname: `./vendorView`, search: `?userId=${prd.merchant_id._id}` }}
                          >

                            <span className="text-primary font-weight-bold">{prd.merchant_id.business_name} </span>
                          </Link> :
                          ''
                        }
                      </span>
                    </div>

                    <div className="d-flex align-items-center py-lg-0 py-2">
                      <div className="d-flex flex-column text-right">
                        <span className="text-dark-75 font-weight-bolder font-size-h4">
                          {prd.tierPrice[0] && prd.tierPrice[0].price ? `$${prd.tierPrice[0].price}` : 'Price'}
                        </span>
                        <span className="text-muted font-size-sm font-weight-bolder">
                          {prd.tierPrice[0] && prd.tierPrice[0].quantity ? `Quantity: ${prd.tierPrice[0].quantity}` : 'in Detail'}
                        </span>
                      </div>
                    </div>
                  </div>
                })}
              </div>
              : null
            }
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <div className='card card-custom card-stretch gutter-b'>

            <div className="card-header pt-3">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Today's Order
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  List of Today's Order
                </span>
              </h3>
              <div className="card-toolbar">
                {(user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user") ?
                  <Link to="/recentUsersList">
                    <button type="button" className="btn btn-link" style={{ borderRadius: "10rem", backgroundColor: "azure" }}
                      data-toggle="tooltip" data-placement="top" title="Today SignUp Users Count"
                    >
                      <span className="badge badge-dark" style={{ borderRadius: "5rem" }}>{orderCount}</span>
                    </button>
                  </Link> :
                  null
                }
              </div>
            </div>

            <div className="card-body pt-2 pb-0">
              <div className="table-responsive">
                <table className="table table-head-custom table-vertical-center table-head-bg">
                  <thead style={{ height: "30px" }}>
                    <tr>
                      <th className="p-0" style={{ width: "100px" }}></th>
                      <th style={{ minWidth: "150px" }}>
                        <span className="text-dark-75 d-flex align-items-start">Purchaser</span>
                      </th>
                      <th style={{ minWidth: "180px" }}>
                        <span className="text-dark-75">Owner</span>
                      </th>
                      <th style={{ minWidth: "130px" }}>
                        <span className="text-dark-75">Order Status</span>
                      </th>
                      <th style={{ minWidth: "130px" }}>
                        <span className="text-dark-75">Order Details</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (orders && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? orders.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val.userId && val.userId.images && val.userId.images.profile_pic && val.userId.images.profile_pic[100] ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val.userId.images.profile_pic[100]})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val.userId._id}` }}
                            >
                              <span className="d-flex align-items-start text-dark-75 text-hover-primary font-size-lg">{val.userId && val.userId.business_name ? val.userId.business_name : ''}</span>
                            </Link>
                          </td>
                          <td className="">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val.productOwnerId._id}` }}
                            >
                              <span className="text-primary font-weight-bold">{val.productOwnerId && val.productOwnerId.business_name ? val.productOwnerId.business_name : ''}</span>
                            </Link>
                          </td>
                          <td className="">
                            <span className="text-muted font-weight-bold">
                              {val.orderStatus}
                            </span>
                          </td>
                          <td className="">
                            <div style={{ cursor: "pointer", lineHeight: "normal" }} title='View Order Details'>
                              {!val.isTrashed ?
                                <Link
                                  className="btn btn-default"
                                  to={{ pathname: `./viewOrder`, search: `?orderId=${val._id}` }}
                                >
                                  <i className="far fa-eye pr-2" aria-hidden="true"></i>
                                </Link>
                                : ''}
                            </div>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header">
              <h3 className="card-title font-weight-bolder text-dark">
                Latest Purchased Vendors
              </h3>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive" style={{ maxHeight: 470 }}>
                <table className="table table-borderless table-vertical-center">
                  <tbody>
                    {
                      (vendorList && vendorList.purchased && vendorList.purchased.hits && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? vendorList.purchased.hits.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val._source && val._source.profile_pic ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val._source.profile_pic})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val._source.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val._source.email}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-6">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header">
              <h3 className="card-title font-weight-bolder text-dark">
                Latest Commission Vendors
              </h3>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive" style={{ maxHeight: 470 }}>
                <table className="table table-borderless table-vertical-center">
                  <tbody>
                    {
                      (vendorList && vendorList.commission && vendorList.commission.hits && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? vendorList.commission.hits.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val._source && val._source.profile_pic ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val._source.profile_pic})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val._source.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val._source.email}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header">
              <h3 className="card-title font-weight-bolder text-dark">
                Latest Manual Vendors
              </h3>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive" style={{ maxHeight: 470 }}>
                <table className="table table-borderless table-vertical-center">
                  <tbody>
                    {
                      (vendorList && vendorList.manual && vendorList.manual.hits && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? vendorList.manual.hits.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val._source && val._source.profile_pic ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val._source.profile_pic})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val._source.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val._source.email}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-6">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header">
              <h3 className="card-title font-weight-bolder text-dark">
                Latest Canceled Vendors
              </h3>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive" style={{ maxHeight: 470 }}>
                <table className="table table-borderless table-vertical-center">
                  <tbody>
                    {
                      (vendorList && vendorList.canceled && vendorList.canceled.hits && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? vendorList.canceled.hits.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val._source && val._source.profile_pic ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val._source.profile_pic})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val._source.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val._source.email}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>


      {/* <div className="row">
        <div className="col-lg-4">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header">
              <h3 className="card-title font-weight-bolder text-dark">
                Latest Expire Vendors
              </h3>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive" style={{ maxHeight: 470 }}>
                <table className="table table-borderless table-vertical-center">
                  <tbody>
                    {
                      (vendorList && vendorList.expire && vendorList.expire.hits && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? vendorList.expire.hits.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val._source && val._source.profile_pic && val._source.profile_pic[100] ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val._source.profile_pic[100]})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val._source.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val._source.email}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-4">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header">
              <h3 className="card-title font-weight-bolder text-dark">
                Latest Refunded Vendors
              </h3>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive" style={{ maxHeight: 470 }}>
                <table className="table table-borderless table-vertical-center">
                  <tbody>
                    {
                      (vendorList && vendorList.refunded && vendorList.refunded.hits && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? vendorList.refunded.hits.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val._source && val._source.profile_pic && val._source.profile_pic[100] ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val._source.profile_pic[100]})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val._source.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val._source.email}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>

        <div className="col-lg-4">
          <div className='card card-custom gutter-b card-stretch'>
            <div className="card-header">
              <h3 className="card-title font-weight-bolder text-dark">
                Latest Payment Failed Vendors
              </h3>
            </div>

            <div className="card-body pt-10 pb-0">
              <div className="table-responsive" style={{ maxHeight: 470 }}>
                <table className="table table-borderless table-vertical-center">
                  <tbody>
                    {
                      (vendorList && vendorList.paymentFailed && vendorList.paymentFailed.hits && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? vendorList.paymentFailed.hits.map(val => (
                        <tr key={val._id}>
                          <td className="pl-0 py-5">
                            <div className="symbol symbol-50 symbol-light mr-2">
                              {val._source && val._source.profile_pic && val._source.profile_pic[100] ?

                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + val._source.profile_pic[100]})`,
                                  }}
                                > </div>
                                :
                                <div
                                  className="symbol-label"
                                  style={{
                                    backgroundImage: `url('/assets/img/default.png')`,
                                  }}
                                ></div>
                              }
                            </div>
                          </td>
                          <td className="pl-0">
                            <Link
                              className="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg"
                              to={{ pathname: `./vendorView`, search: `?userId=${val._id}` }}
                            >
                              <span className="text-primary font-weight-bold d-flex align-items-start">{val._source.business_name}</span>
                            </Link>
                            <span className="text-muted font-weight-bold d-block d-flex align-items-start">
                              {val._source.email}
                            </span>
                          </td>
                        </tr>
                      )) :
                        null
                    }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>  */}

      <div className="row">
        <div className="col-xl-6">
          <div className='card card-custom card-stretch gutter-b'>
            <div className="card-header border-0">
              <h3 className="card-title font-weight-bolder text-dark">
                Subscription Expiry Notifications
              </h3>
            </div>
            {(user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user") ?
              <div className="card-body pt-0">
                {expStats.map((item, key) => {
                  return <div className="mb-6" key={key}>
                    <div className="d-flex align-items-center flex-grow-1">
                      {/* <label className="checkbox checkbox-lg checkbox-lg checkbox-single flex-shrink-0 mr-4">
                        <input type="checkbox" value="1" />
                        <span></span>
                      </label> */}

                      <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                        <div className="symbol symbol-50 symbol-light mr-2">
                          {item.images && item.images.profile_pic && item.images.profile_pic[100] ?

                            <div
                              className="symbol-label"
                              style={{
                                backgroundImage: `url(${process.env.REACT_APP_BUCKET_URL + process.env.REACT_APP_PRODUCT_IMAGE_URL + '/' + item.images.profile_pic[100]})`,
                              }}
                            > </div>
                            :
                            <div
                              className="symbol-label"
                              style={{
                                backgroundImage: `url('/assets/img/default.png')`,
                              }}
                            ></div>
                          }
                        </div>
                        <div className="d-flex flex-column align-items-cente py-2 w-75">
                          <Link
                            to={{ pathname: `./vendorView`, search: `?userId=${item._id}` }}
                            className="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1"
                          >
                            {item.business_name}
                          </Link>

                          <span className="text-muted font-weight-bold">
                            {`${Math.round((new Date(item.sellerSubscriptionExpiryDate).getTime() - new Date().getTime()) / (24 * 3600 * 1000))} days left`}
                          </span>
                        </div>

                        <span className={`label label-lg label-light-${item.sellerSubscriptionType === 'Purchased' ? 'primary' : 'warning'} label-inline font-weight-bold py-4`}>
                          {item.sellerSubscriptionType}
                        </span>
                      </div>
                    </div>
                  </div>
                })}
              </div>
              : null
            }
          </div>
        </div>

        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className='card card-custom gutter-b'>
                <div className="card-body p-0">
                  <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                    <span
                      className={`symbol circle symbol-50 symbol-light-standard mr-2`}
                    >
                      <span className="symbol-label">
                        <i className="fas  fa-file-upload fa-2x text-info" ></i>
                      </span>
                    </span>
                    <div className="d-flex flex-column text-right">
                      <span className="text-dark-75 font-weight-bolder font-size-h4">
                        Upload your Files
                      </span>
                    </div>
                  </div>

                  <div className="card-spacer bg-light-warning card-rounded flex-grow-1">
                    <div className="row m-0">
                      <div className="col px-8 py-6 mr-8" style={{ textAlign: 'center' }}>
                        <Badge pill variant="light" className="font-size-h4 font-weight-bolder">
                          {isAuthenticated ? uploadScript : ""}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 mt-5">
              <div className='card card-custom gutter-b'>
                <div className="card-body p-0">
                  <div className="d-flex align-items-center justify-content-between card-spacer flex-grow-1">
                    <span
                      className={`symbol circle symbol-50 symbol-light-standard mr-2`}
                    >
                      <span className="symbol-label">
                        <i className="fas  fa-user fa-2x text-info" ></i>
                      </span>
                    </span>
                    <div className="d-flex flex-column text-right">
                      <span className="text-dark-75 font-weight-bolder font-size-h4">
                        Admin can create user
                      </span>
                    </div>
                  </div>

                  <div className="card-spacer bg-light-danger card-rounded flex-grow-1">
                    <div className="row m-0">
                      <div className="col px-8 py-6 mr-8" style={{ textAlign: 'center' }}>
                        <Badge pill variant="light" className="font-size-h4 font-weight-bolder">
                          {isAuthenticated ? createUser : ""}
                        </Badge>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {})(Dashboard);






// <div className="col">

// <div className="row">
//   <div className="col-lg-3 col-md-6 col-sm-6">
//     <div className={`card card-custom ${className}`}>
//       <div className="card-header border-0">
//         <h1 className="card-title font-weight-bolder">
//           <i className="fas  fa-file-upload fa-2x text-info" ></i>
//         </h1>
//         <div className="card-toolbar">
//           <h3 className="mb-0"><Badge pill variant="light">
//             {isAuthenticated ? uploadScript : ""}
//           </Badge></h3>
//         </div>
//       </div>
//       <div className="card-body m-0 p-0">
//         <h5 className="card-title font-weight-bolder text-muted ml-8 mb-1 mt-0">
//           Upload your Files
//         </h5>
//       </div>
//     </div>
//   </div>

//   <div className="col-lg-3 col-md-6 col-sm-6">
//     <div className={`card card-custom ${className}`}>
//       <div className="card-header border-0">
//         <h1 className="card-title font-weight-bolder">
//           <i className="fas  fa-user fa-2x text-info" ></i>
//         </h1>
//         <div className="card-toolbar">
//           <h3 className="mb-0"><Badge pill variant="light">
//             {isAuthenticated ? createUser : ""}
//           </Badge></h3>
//         </div>
//       </div>
//       <div className="card-body m-0 p-0">
//         <h5 className="card-title font-weight-bolder text-muted ml-8 mb-1 mt-0">
//           Admin can create user
//         </h5>
//       </div>
//     </div>
//   </div>

//   <div className="col-lg-3 col-md-6 col-sm-6">
//     <div className={`card card-custom ${className}`}>
//       <div className="card-header border-0">
//         <h1 className="card-title font-weight-bolder">
//           <i className="fab fa-twitter fa-2x text-info" ></i>
//         </h1>
//         <div className="card-toolbar">
//           <h3 className="mb-0"><Badge pill variant="primary">
//             +245
//           </Badge></h3>
//         </div>
//       </div>
//       <div className="card-body m-0 p-0">
//         <h5 className="card-title font-weight-bolder text-muted mr-8 mb-1 mt-0 float-right">
//           Followers
//         </h5>
//       </div>
//     </div>
//   </div>
// </div>

// <div className="row">
//   <div className="col-md-4">
//     <div className={`card card-custom ${className}`}>
//       {/* Header */}
//       <div className="card-header border-0">
//         <h3 className="card-title font-weight-bolder text-dark">
//           Active Banner Products
//         </h3>
//         <div className="card-toolbar">
//           <span className="label label-lg label-light-success label-inline font-weight-bold py-4">
//             {stats && stats.Banner}
//           </span>
//           <h3 className="mb-0"><Badge pill variant="primary">
//             {stats && stats.Banner}
//           </Badge></h3>
//         </div>
//       </div>
//     </div>
//   </div>

//   <div className="col-md-4">
//     <div className={`card card-custom ${className}`}>
//       <div className="card-header border-0">
//         <h3 className="card-title font-weight-bolder text-dark">
//           Active Top Companies
//         </h3>
//         <div className="card-toolbar">
//           <h3 className="mb-0"><Badge pill variant="primary">
//             {stats && stats.TopCompany}
//           </Badge></h3>
//         </div>
//       </div>
//     </div>

//     <div className="card card-chart">
//       <div className="card-header card-header-warning">
//         <div className="ct-chart" id="websiteViewsChart"></div>
//       </div>
//       <div className="card-body">
//         <h4 className="card-title">Active Top Companies
//           <p className="card-category" style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top: '-1em', color: '#a72828' }}>
//             <span className="badge badge-info" style={{ borderRadius: '5rem' }}>
//               {stats && stats.TopCompany}
//             </span>
//           </p>
//         </h4>
//       </div>
//       <div className="card-footer">
//         <div className="stats">
//           <i className="material-icons">access_time</i> campaign sent 2
//           days ago
//         </div>
//       </div>
//     </div>
//   </div>

//   <div className="col-md-4">
//     <div className={`card card-custom ${className}`}>
//       <div className="card-header border-0">
//         <h3 className="card-title font-weight-bolder text-dark">
//           Active WholeSale Members
//         </h3>
//         <div className="card-toolbar">
//           <h3 className="mb-0"><Badge pill variant="primary">
//             {stats && stats.NewestMember}
//           </Badge></h3>
//         </div>
//       </div>
//     </div>
//     <div className="card card-chart">
//       <div className="card-header card-header-danger">
//         <div className="ct-chart" id="completedTasksChart"></div>
//       </div>
//       <div className="card-body">
//         <h4 className="card-title">Active WholeSale Members
//           <p className="card-category" style={{ display: 'flex', justifyContent: 'flex-end', position: 'relative', top: '-1em', color: '#a72828' }}>
//             <span className="badge badge-info" style={{ borderRadius: '5rem' }}>
//               {stats && stats.NewestMember}
//             </span>
//           </p>
//         </h4>
//       </div>
//       <div className="card-footer">
//         <div className="stats">
//           <i className="material-icons">access_time</i> campaign sent 2
//           days ago
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

// <div className="row mt-10">
//   <div className="col-lg-8 col-md-12 col-xl-6">
//     <div className={`card card-custom ${className}`}>
//       <div className="card-header border-0">
//         <h1 className="card-title font-weight-bolder">
//           <i className="fas  fa-users fa-2x text-info" ></i>
//           <span className="ml-2 p-1">List Of today SignUp Users</span>
//         </h1>
//         <div className="card-toolbar">
//           {(user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user") ?
//             <Link to="/recentUsersList">
//               <button type="button" className="btn btn-link" style={{ borderRadius: "10rem", backgroundColor: "azure" }}
//                 data-toggle="tooltip" data-placement="top" title="Today SignUp Users Count"
//               >
//                 <span className="badge badge-dark" style={{ borderRadius: "5rem" }}>{userStatsCount}</span>
//               </button>
//             </Link> :
//             null
//           }
//         </div>
//       </div>
//       <div className="card-body pt-2">
//         <div className="table-responsive">
//           <table className="table table-hover align-middle gs-0 gy-4">
//             <thead className="text-warning">
//               <tr>
//                 <th className="min-w-125px">Username</th>
//                 <th className="min-w-125px">Email</th>
//                 <th className="min-w-125px">businessTypeName</th>
//                 <th className="min-w-125px">Email Verified</th>
//               </tr>
//             </thead>
//             <tbody>
//               {
//                 (userList && (user.role === "admin" || user.role === "teamlead" || user.role === 'admin_readOnly' || user.role === 'readOnly_user' || user.role === "dataentry_user" || user.role === "seomanager_user")) ? userList.map(val => (
//                   <tr key={val._id}>
//                     <td>{val.username}</td>
//                     <td>{val.email}</td>
//                     <td>{val.businessTypeName}</td>
//                     <td>{val.email_verification ? 'True' : 'False'}</td>
//                   </tr>
//                 )) :
//                   null
//               }
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>

// </div>