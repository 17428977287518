/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { useLocation } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";




export function StatisticsMenuList({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    const { user, isAuthenticated } = useSelector((state) => state.auth)
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {isAuthenticated ? (
                    <>

                        {(user.role === "admin" || user.role === "admin_readOnly" || user.role === "dataentry_user" || user.role === 'seomanager_user' || user.role === "readOnly_user") ? (
                            <>
                                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                                    <NavLink className="menu-link menu-toggle" to="/Vendors">
                                        <i className="fas fa-user menu-icon"></i>
                                        <span className="menu-text">User Stats</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <ul className="menu-subnav">

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/signupStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/signupStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Sign up Users</span>
                                                </NavLink>
                                            </li>

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/signinStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/signinStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Sign in Users</span>
                                                </NavLink>
                                            </li>

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/postStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/postStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Posts</span>
                                                </NavLink>
                                            </li>

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/postLikesStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/postLikesStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Post Likes</span>
                                                </NavLink>
                                            </li>

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/shortListPostStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/shortListPostStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Short List Post</span>
                                                </NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </li>


                                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                                    <NavLink className="menu-link menu-toggle" to="/Products">
                                        <i className="fas fa-box menu-icon"></i>
                                        <span className="menu-text">Product Stats</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <ul className="menu-subnav">

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/productStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/productStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Products</span>
                                                </NavLink>
                                            </li>

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/shortListProductStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/shortListProductStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Short List Product</span>
                                                </NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </li>

                                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                                    <NavLink className="menu-link menu-toggle" to="/Orders">
                                        <i className="fas fa-sort-amount-down-alt menu-icon"></i>
                                        <span className="menu-text">Order Stats</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <ul className="menu-subnav">

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/placedOrderStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/placedOrderStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Placed Order</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>


                                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                                    <NavLink className="menu-link menu-toggle" to="/Sales">
                                        <i className="fas fa-box menu-icon"></i>
                                        <span className="menu-text">Sales Stats</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <ul className="menu-subnav">

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/productSalesStats"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/productSalesStats"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Product Sales</span>
                                                </NavLink>
                                            </li>

                                            {/* <li className={`menu-item  ${getMenuItemActive(
                                                "/topSellingProducts"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/topSellingProducts"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Top Selling Products</span>
                                                </NavLink>
                                            </li> */}

                                        </ul>
                                    </div>
                                </li>


                                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                                    <NavLink className="menu-link menu-toggle" to="/Contacts">
                                        <i className="fas fa-address-card menu-icon"></i>
                                        <span className="menu-text">Contact Stats</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <ul className="menu-subnav">

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/contactRequests"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/contactRequests"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Contact Requests</span>
                                                </NavLink>
                                            </li>

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/contactInvite"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/contactInvite"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Contact Invited</span>
                                                </NavLink>
                                            </li>

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/contactCounts"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/contactCounts"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Contact Counts</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                                    <NavLink className="menu-link menu-toggle" to="/Message">
                                        {/* <i className="fas fa-message menu-icon"></i> */}
                                        <i className="fas fa-comment menu-icon"></i>
                                        <span className="menu-text">Message Stats</span>
                                        <i className="menu-arrow" />
                                    </NavLink>
                                    <div className="menu-submenu">
                                        <ul className="menu-subnav">

                                            <li className={`menu-item  ${getMenuItemActive(
                                                "/messageCounts"
                                            )}`}>
                                                <NavLink
                                                    className="menu-link "
                                                    to="/messageCounts"
                                                >
                                                    <i className="menu-bullet menu-bullet-dot">
                                                        <span />
                                                    </i>
                                                    <span className="menu-text">Message Counts</span>
                                                </NavLink>
                                            </li>

                                        </ul>
                                    </div>
                                </li>

                            </>
                        ) : (
                            ""
                        )}

                    </>
                ) : (
                    ""
                )}
                {/*end::1 Level*/}
                {/** END HERE */}

            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
