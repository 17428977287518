import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_seebizsol/_partials/controls";
// import { useLocation } from "react-router-dom";
import axios from "axios";

function SendInvitationEmailForPKG(props) {
    // Fields
    // const { profile } = props;
    const [loading, setloading] = useState(false);
    const [alert, setAlert] = useState("");
    const [error, setError] = useState("");

    // Methods
    // let userId = new URLSearchParams(useLocation().search).get("userId");

    async function changeEmail(updatedUser) {
        try {
            // console.log('updatedUser --- ', updatedUser)
            const res = await axios.post("/api/users/sendInvitationEmail", updatedUser);
            if (res.status === 200 && !res.data.status) {
                // setAlert(`${updatedUser.email}\n${res.data.message}`);
                setAlert([
                    <span style={{ color: '#1560bd' }}>{updatedUser.email}</span>,
                    <br key="break" />,
                    res.data.message
                ])
                
                // const { email } = res.data.user;
                // props.updateEmail(prevState => ({
                //     ...prevState,
                //     email: email,
                // }));
                formik.values.email = ''
            } else {
                // setError(`${updatedUser.email}\n${res.data.message}`);
                setError([
                    <span style={{ color: '#1560bd' }}>{updatedUser.email}</span>,
                    <br key="break" />,
                    res.data.message
                ])
            }
        } catch (err) {
            console.log(err.message);
        }
    }

    const saveUser = (values, setStatus, setSubmitting) => {
        setAlert("");
        setError("");
        setloading(true);

        const updatedUser = Object.assign(values, {
            // userId: userId
        });
        changeEmail(updatedUser);

        setTimeout(() => {
            setloading(false);
            setSubmitting(false);
            // setAlert("");
            // setError("");
        }, 5000);
    };
    // UI Helpers
    const initialValues = {
        email: ""
    };

    const Schema = Yup.object().shape({
        email: Yup.string()
            .email("Wrong email format").required(),
    });

    const getInputClasses = fieldname => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }
        // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        //   return "is-valid";
        // }
        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveUser(values, setStatus, setSubmitting);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        }
    });


    return (
        <div className="content">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <form className="card card-custom" onSubmit={formik.handleSubmit}>
                            {loading && <ModalProgressBar />}

                            {/* begin::Header */}
                            <div className="card-header py-3">
                                <div className="card-title align-items-start flex-column">
                                    <h3 className="text-primary">Send Email for new Package</h3>
                                    <h5 className="mt-0 font-size-sm text-muted">
                                        Send Email to invite Users for new Package
                                    </h5>
                                </div>
                                {/* <div className="card-toolbar">
                    <button
                        type="submit"
                        className="btn btn-success mr-2"
                        disabled={
                            formik.isSubmitting || (formik.touched && !formik.isValid)
                        }
                    >
                        Save Changes
                        {formik.isSubmitting}
                    </button>
                    <Link to="/listUsers" className="btn btn-secondary">
                        Cancel
                    </Link>
                </div> */}
                            </div>
                            {/* end::Header */}
                            {/* begin::Form */}
                            <div className="form">
                                <div className="card-body">
                                    {/* begin::Alert */}
                                    {alert !== "" && (
                                        <div
                                            className="alert alert-custom alert-light-primary fade show mb-10"
                                            role="alert"
                                        >
                                            <div className="alert-text font-weight-bold" style={{ whiteSpace: "pre-line", fontWeight: 'bold', fontSize: '15px' }}>{alert}</div>
                                            <div className="alert-close" onClick={() => setAlert("")}>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">
                                                        <i className="sbi sbi-close"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    )}

                                    {error !== "" && (
                                        <div
                                            className="alert alert-custom alert-light-danger fade show mb-10"
                                            role="alert"
                                        >
                                            <div className="alert-text font-weight-bold" style={{ whiteSpace: "pre-line", fontWeight: 'bold', fontSize: '15px' }}>{error}</div>
                                            <div className="alert-close" onClick={() => setError("")}>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">
                                                        <i className="sbi sbi-close"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                    {/* end::Alert */}

                                    <div className="form-group row">
                                        <label className="col-xl-2 col-lg-2 col-form-label" style={{ fontWeight: 'bold' }}>
                                            Enter Email
                                        </label>
                                        <div className="col-lg-10 col-xl-10">
                                            <div className="input-group input-group-lg input-group-solid">
                                                <span className="input-group-text">
                                                    <i className="fa fa-at"></i>
                                                </span>
                                                <input
                                                    type="email"
                                                    placeholder="New Email"
                                                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                                        "email"
                                                    )}`}
                                                    name="email"
                                                    {...formik.getFieldProps("email")}
                                                />
                                            </div>
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="invalid-feedback display-block">
                                                    {formik.errors.email}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>


                                    <div className="form-group row d-flex justify-content-end">
                                        <button
                                            type="submit"
                                            className="btn btn-success mr-3"
                                            disabled={
                                                formik.isSubmitting || (formik.touched && !formik.isValid)
                                            }
                                        >
                                            Send Email
                                            {formik.isSubmitting}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* end::Form */}
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendInvitationEmailForPKG;
