import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import PropTypes from "prop-types";
import { useSubheader } from "../../_seebizsol/layout";
import { useLocation } from "react-router-dom";
import axios from 'axios';


const UpdateAdminUser = ({ setAlert, isAuthenticated }) => {
    const subheader = useSubheader();
    subheader.setTitle("Update Admin User Account")
    const [formData, setFormData] = useState({
        _id: "",
        name: "",
        email: "",
        password: "",
        password2: "",
        role: "",
        changePas: 'no',
        enable: true
    });

    let userId = (new URLSearchParams(useLocation().search)).get("userId");

    useEffect(() => {
        axios.get(`/api/users/getAdminUserInfo/${userId}`)
            .then(res => {
                const { _id, name, email, role, enable } = res.data;
                // console.log("res ==== ", res.data);

                setFormData({
                    _id: _id,
                    name: name,
                    email: email,
                    role: role,
                    password: "",
                    password2: "",
                    changePas: 'no',
                    enable: enable === false ? false : true
                });
            })
            .catch(er => console.log(er));
    }, [userId]);


    const { name, email, password, password2, role, changePas, enable } = formData;


    const onChange = (e) =>
        setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        // console.log('JSON.stringify(formData', JSON.stringify(formData));
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (name === '' || email === '' || role === '' || enable === '' || (changePas === 'yes' && (password === '' || password2 === ''))) {
            setAlert("Please Fill All Required Fields", "danger");
        } else if (changePas === 'yes' && password !== password2) {
            setAlert("Passwords did not match", "danger");
        } else if (changePas === 'yes' && password.length < 6) {
            setAlert("Please enter a password with 6 or more characters ", "danger");
        } else if (!email.match(validRegex)) {
            setAlert("Please enter a Valid Email address ", "danger");
        } else {
            // console.log('formData --- ', formData);
            const res = await axios.post("/api/users/updateUser", formData);
            // console.log("res ", res.data);
            if (res.data.status === true) {
                setAlert(res.data.msg, 'success', 10000);
                // setFormData({
                //     name: "",
                //     email: "",
                //     password: "",
                //     password2: "",
                //     role: "",
                // })
            } else {
                setAlert(res.data.msg, 'danger', 10000);
            }
        }
    };

    return (
        <div className="container-fluid container-lg">
            <div className="row justify-content-center">
                <div className="col-md-8 mt-2">
                    <div className="card card-custom">
                        <div className="card-header border-0 pt-4">
                            <div className="card-title align-items-start flex-column">
                                <h3 className="text-primary">
                                    Update Admin Profile
                                </h3>
                                <h5 className="mt-0 font-size-sm text-muted">
                                    update admin user details
                                </h5>
                            </div>
                        </div>
                        <div className="card-body pt-1">
                            <form className="form" onSubmit={(e) => onSubmit(e)}>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <label className="bmd-label-floating">
                                                Enter Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                value={name}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <label className="bmd-label-floating">
                                                Email address <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                name="email"
                                                value={email}
                                                onChange={(e) => onChange(e)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group row align-items-center">
                                    <label className="col-xl-4 col-lg-4 col-form-label">
                                        Do you want to change password
                                    </label>
                                    <div className="col-lg-6 col-xl-6">
                                        <div className="radio-inline">
                                            <label className="radio">
                                                <input
                                                    type="radio"
                                                    name="changePas"
                                                    value="yes"
                                                    checked={changePas === 'yes' ? true : false}
                                                    onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                                />
                                                <span></span>Yes
                                            </label>
                                            <label className="radio">
                                                <input
                                                    type="radio"
                                                    name="changePas"
                                                    value="no"
                                                    checked={changePas === 'no' ? true : false}
                                                    onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                                                />
                                                <span></span>No
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {
                                    changePas === 'yes' ?
                                        <>
                                            <div className="row">
                                                <div className="col-md">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating"
                                                        >Password <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password"
                                                            value={password}
                                                            onChange={(e) => onChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md">
                                                    <div className="form-group">
                                                        <label className="bmd-label-floating">
                                                            Confirm Password <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name="password2"
                                                            value={password2}
                                                            onChange={(e) => onChange(e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        : 
                                        ''
                                }


                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group">
                                            <label className="bmd-label-floating">
                                                Select User Role <span className="text-danger">*</span>
                                            </label>
                                            <select
                                                className="form-control"
                                                name="role"
                                                value={role}
                                                onChange={(e) => onChange(e)}
                                            >
                                                <option value="">* Select User Role</option>
                                                <option value="basic_user">Basic User</option>
                                                <option value="sem_user">SEM User</option>
                                                <option value="dataentry_user">Data Entry User</option>
                                                <option value="readOnly_user">Read Only User</option>
                                                <option value="seomanager_user">SEO Manager</option>
                                                <option value="helpdesk_user">Help Desk User</option>
                                                <option value="admin_readOnly">Admin Read Only</option>
                                                <option value="seo_user">SEO User</option>
                                                <option value="seo_intern">SEO Intern</option>
                                            </select>
                                            <small className="form-text">
                                                Give you an idea of what a user can perform
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md">
                                        <div className="form-group row align-items-center">
                                            <label className="col-xl-4 col-lg-4 col-form-label">
                                                Enable User
                                            </label>
                                            <div className="col-lg-6 col-xl-6">
                                                <div className="checkbox-inline">
                                                    <label className="checkbox">
                                                        <input
                                                            type="checkbox"
                                                            name="enable"
                                                            checked={enable}
                                                            onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.checked })}
                                                        />
                                                        <span></span>
                                                        <p className="form-text" style={{ fontSize: 13, marginBottom: 0,  color: `${enable ? 'green' : 'red'}` }}>{enable ? 'Enabled' : 'Disabled'}</p>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <input
                                    type="submit"
                                    className="btn btn-primary"
                                    value="Update User"
                                />
                                <div className="clearfix"></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UpdateAdminUser.propTypes = {
    setAlert: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.auth.isAuthenticated,
    };
};

export default connect(mapStateToProps, { setAlert })(UpdateAdminUser);
