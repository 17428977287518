/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
// import { useLocation } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

function mTags(getMenuItemActive) {
  return (
    <li className={`menu-item  ${getMenuItemActive?.(
      "/metaTags"
    )}`}>
      <NavLink
        className="menu-link"
        to="/metaTags"
      >
        <i className="fas fa-tags menu-icon"></i>
        <span className="menu-text">Meta tags </span>
      </NavLink>
    </li>
  );
}
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const { user, isAuthenticated } = useSelector((state) => state.auth)
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>

        {isAuthenticated ? (
          <>

            {user.role === "admin" || user.role === "teamlead" ? (
              <>
                <li
                  className={`menu-item text-info ${getMenuItemActive(
                    "/dashboard", false
                  )}`}
                  aria-haspopup="true"
                >
                  {(user.role === "admin" || user.role === "teamlead") ? (
                    <NavLink
                      className="menu-link"
                      to="/dashboard"
                    >
                      <i className="sbi-gf gf-dashboard  menu-icon"></i>
                      <span className="menu-text" >Dashboard</span>
                    </NavLink>
                  ) : (
                    ""
                  )}
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link menu-toggle" to="/Products">
                    <i className="fas fa-box menu-icon" ></i>
                    <span className="menu-text">Products</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">

                      <li className={`menu-item ${getMenuItemActive(
                        "/listProducts"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listProducts"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Products</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDbProducts"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDbProducts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List DB Products</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDraftProducts"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDraftProducts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List Draft Products</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      {/* <li className={`menu-item ${getMenuItemActive(
                        "/updateProductPrice"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/updateProductPrice"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Update Product Price </span>
                        </NavLink>
                      </li> */}

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listProductRelatedLinks"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listProductRelatedLinks"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Related Links</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/uploadProductRelatedLinks"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/uploadProductRelatedLinks"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Upload Related links</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDeletedProducts"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDeletedProducts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List Deleted Products</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listShopifyProducts"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/listShopifyProducts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Shopify Integrated Products</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link menu-toggle" to="/bulkProducts">
                    <i className="fas fa-boxes menu-icon" ></i>
                    <span className="menu-text">Product Bulk</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">

                      <li className={`menu-item  ${getMenuItemActive(
                        "/uploadproduct"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/uploadproduct"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Upload Bulk file</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/uplodedFiles"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/uplodedFiles"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Uploaded Files Status</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/bulkUpdateproduct"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/bulkUpdateproduct"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Update Bulk file</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/updatedFiles"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/updatedFiles"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Updated Files Status</span>
                        </NavLink>
                      </li>

                    </ul>
                  </div>
                </li>


                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link  menu-toggle" to="/Categories">
                    <i className="sbi-gf gf-category menu-icon" ></i>
                    <span className="menu-text">Categories</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCategories"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listCategories"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Categories</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/addCategory"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/addCategory"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add Category</span>
                        </NavLink>
                      </li>

                      {user.role === "admin" ? (
                        <li className={`menu-item  ${getMenuItemActive(
                          "/listApprovalCategories"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/listApprovalCategories"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List Approval Categories</span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      )}


                      {user.role === "admin" ? (
                        <>
                          <li className={`menu-item  ${getMenuItemActive(
                            "/L1Categories"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/L1Categories"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List L1 Categories</span>
                            </NavLink>
                          </li>

                          {/* <li className={`menu-item  ${getMenuItemActive(
                            "/L2Categories"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/L2Categories"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List L2 Categories</span>
                            </NavLink>
                          </li> */}

                          <li className={`menu-item  ${getMenuItemActive(
                            "/updateBulkCategory"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/updateBulkCategory"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Update Bulk Category</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/listBulkUpdateCategories"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listBulkUpdateCategories"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Updated Category Files</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      <li className={`menu-item  ${getMenuItemActive(
                        "/bulkCategory"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/bulkCategory"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Upload Bulk Categories</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listBulkCategories"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listBulkCategories"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Bulk Category files</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/uploadCategoryRelatedLinks"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/uploadCategoryRelatedLinks"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Upload Related links</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCategoryRelatedLinks"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listCategoryRelatedLinks"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Related Links</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadCategoryCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadCategoryCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Catalogs">
                    <i className="fas fa-sitemap menu-icon"></i>
                    <span className="menu-text">Catalogs</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCatalogs"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listCatalogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Catalogs</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/addCatalog"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/addCatalog"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add Catalog</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/bulkCatalog"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/bulkCatalog"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Bulk Upload Catalog</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listBulkCatalog"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listBulkCatalog"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Bulk Catalog list</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>


                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Vendors">
                    <i className="sbi-gf gf-construction menu-icon"></i>
                    <span className="menu-text">Vendors</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listUsers"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listUsers"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Vendors</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDbUsers"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDbUsers"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List DB Vendors</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadUserCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadUserCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/recentUsersList"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/recentUsersList"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Recent Vendors</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listInActiveUsers"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/listInActiveUsers"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List inActive Vendors</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listUserRelatedLinks"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listUserRelatedLinks"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Related Links</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/uploadUserRelatedLinks"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/uploadUserRelatedLinks"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Upload Related Links</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/deleteBulkUsers"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/deleteBulkUsers"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Delete Bulk Users</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listDeletedUsers"
                      )}`}>
                        {user.role === "admin" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDeletedUsers"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List Deleted Users</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>
                      
                    </ul>
                  </div>
                </li>

                {user.role === "admin" ? (
                  <>
                    <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                      <NavLink className="menu-link menu-toggle" to="/Contacts">
                        <i className="fas fa-address-card menu-icon"></i>
                        <span className="menu-text">Contacts</span>
                        <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu">
                        <ul className="menu-subnav">

                          <li className={`menu-item  ${getMenuItemActive(
                            "/listContactGroups"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listContactGroups"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Contact Groups</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/listContactRequest"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listContactRequest"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Contact Request</span>
                            </NavLink>
                          </li>

                        </ul>
                      </div>
                    </li>

                    <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                      <NavLink className="menu-link menu-toggle" to="/VendorListing">
                        <i className="fas fa-copy menu-icon"></i>
                        <span className="menu-text">Vendors Listing</span>
                        <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu">
                        <ul className="menu-subnav">

                          <li className={`menu-item ${getMenuItemActive(
                            "/addVendorsDesc"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/addVendorsDesc"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Add User and Desc</span>
                            </NavLink>
                          </li>
                          <li className={`menu-item ${getMenuItemActive(
                            "/listVendors"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listVendors"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Vendors</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/listdownloadVendorListingCsv"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listdownloadVendorListingCsv"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Download Csv File</span>
                            </NavLink>
                          </li>

                        </ul>
                      </div>
                    </li>

                    <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                      <NavLink className="menu-link menu-toggle" to="/qaVendors">
                        <i className="fas fa-bus menu-icon"></i>
                        <span className="menu-text">QA Vendors</span>
                        <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu">
                        <ul className="menu-subnav">

                          <li className={`menu-item ${getMenuItemActive(
                            "/addQaVendors"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/addQaVendors"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Add QA Vensors</span>
                            </NavLink>
                          </li>
                          <li className={`menu-item ${getMenuItemActive(
                            "/listQaVendors"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listQaVendors"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List QA Vensors</span>
                            </NavLink>
                          </li>

                        </ul>
                      </div>
                    </li>

                    <li className={`menu-item menu-item-submenu`}>
                      <NavLink className="menu-link menu-toggle" to="/Products">
                        <i className="fas fa-sort-amount-down-alt menu-icon"> </i>
                        <span className="menu-text">Product Orders</span>
                        <i className="menu-arrow" />
                      </NavLink>
                      <div className="menu-submenu ">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">

                          {/* Product Inquiries Start */}
                          <li className={`menu-item ${getMenuItemActive(
                            "/listProductInquiries"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listProductInquiries"
                            >
                              {/* <i className="fas fa-table menu-icon"></i> */}
                              <span className="menu-text">List Product Inquiries</span>
                            </NavLink>
                          </li>
                          {/* Product Inquiries End*/}

                          {/* Product Quotation Start */}
                          {/* <li className={`menu-item ${getMenuItemActive(
                            "/listQuotations"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listQuotations"
                            >
                              <i className="fab fa-accusoft menu-icon"></i>
                              <span className="menu-text">List Quotations</span>
                            </NavLink>
                          </li> */}

                          <li className={`menu-item ${getMenuItemActive(
                            "/listRfqQuotations"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listRfqQuotations"
                            >
                              {/* <i className="fab fa-accusoft menu-icon"></i> */}
                              <span className="menu-text">List Rfq Quotations</span>
                            </NavLink>
                          </li>
                          {/* Product Quotaion End*/}

                          {/* <li className={`menu-item ${getMenuItemActive(
                            "/listESRfqs"
                          )}`}>
                            {user.role === "admin" ? (
                              <NavLink
                                className="menu-link "
                                to="/listESRfqs"
                              >
                                <i className="fab fa-accusoft menu-icon"></i>
                                <span className="menu-text">List ES Rfq Quotations</span>
                              </NavLink>
                            ) : (
                              ""
                            )}
                          </li> */}
                          {/* Product Quotaion End*/}

                          {/* Product Order Start */}
                          <li className={`menu-item ${getMenuItemActive(
                            "/productOrder"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/productOrder"
                            >
                              {/* <i className="fas fa-sort-amount-down-alt menu-icon"> </i> */}
                              <span className="menu-text">List Product Orders</span>
                            </NavLink>
                          </li>
                          {/* Product Order End*/}

                          {/* <li className={`menu-item ${getMenuItemActive(
                            "/listESOrders"
                          )}`}>
                            {user.role === "admin" ? (
                              <NavLink
                                className="menu-link "
                                to="/listESOrders"
                              >
                                <i className="fas fa-sort-amount-down-alt menu-icon"> </i>
                                <span className="menu-text">List ES Product Orders</span>
                              </NavLink>
                            ) : (
                              ""
                            )}
                          </li> */}

                        </ul>
                      </div>
                    </li>

                    {/* Stripe Start */}
                    {/* <li className={`menu-item ${getMenuItemActive(
                      "/listStripe"
                    )}`}>
                      <NavLink
                        className="menu-link "
                        to="/listStripe"
                      >
                        <i className="fas fa-user menu-icon"> </i>
                        <span className="menu-text">List Stripe Data</span>
                      </NavLink>
                    </li> */}
                    {/* Stripe End*/}
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {(user.role === "admin_readOnly" || user.role === "seomanager_user" || user.role === "dataentry_user" || user.role === "readOnly_user") ? (
              <>
                <li
                  className={`menu-item text-info ${getMenuItemActive(
                    "/dashboard", false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink
                    className="menu-link"
                    to="/dashboard"
                  >
                    <i className="sbi-gf gf-dashboard  menu-icon"></i>
                    <span className="menu-text" >Dashboard</span>
                  </NavLink>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover"
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link menu-toggle" to="/Products">
                    <i className="fas fa-box menu-icon" ></i>
                    <span className="menu-text">Products</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">

                      <li className={`menu-item ${getMenuItemActive(
                        "/listProducts"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listProducts"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Products</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDbProducts"
                      )}`}>
                        {user.role === "dataentry_user" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDbProducts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List DB Products</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDraftProducts"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listDraftProducts"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Draft Products</span>
                        </NavLink>
                      </li>

                      {(user.role === "seomanager_user") ? (
                        <>
                          <li className={`menu-item  ${getMenuItemActive(
                            "/listProductRelatedLinks"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listProductRelatedLinks"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Related Links</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/uploadProductRelatedLinks"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/uploadProductRelatedLinks"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Upload Related links</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDeletedProducts"
                      )}`}>
                        {user.role === "dataentry_user" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDeletedProducts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List Deleted Products</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listShopifyProducts"
                      )}`}>
                        {user.role === "dataentry_user" ? (
                          <NavLink
                            className="menu-link "
                            to="/listShopifyProducts"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Shopify Integrated Products</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                    </ul>
                  </div>
                </li>

                {user.role === "dataentry_user" ? (
                  <li className="menu-item menu-item-submenu" data-menu-toggle="hover"
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link menu-toggle" to="/bulkProducts">
                      <i className="fas fa-boxes menu-icon" ></i>
                      <span className="menu-text">Product Bulk</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu">
                      <ul className="menu-subnav">

                        <li className={`menu-item  ${getMenuItemActive(
                          "/uploadproduct"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/uploadproduct"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Upload Bulk file</span>
                          </NavLink>
                        </li>

                        <li className={`menu-item  ${getMenuItemActive(
                          "/uplodedFiles"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/uplodedFiles"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Uploaded Files Status</span>
                          </NavLink>
                        </li>

                        <li className={`menu-item  ${getMenuItemActive(
                          "/bulkUpdateproduct"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/bulkUpdateproduct"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Update Bulk file</span>
                          </NavLink>
                        </li>

                        <li className={`menu-item  ${getMenuItemActive(
                          "/updatedFiles"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/updatedFiles"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Updated Files Status</span>
                          </NavLink>
                        </li>

                      </ul>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Catalogs">
                    <i className="fas fa-sitemap menu-icon"></i>
                    <span className="menu-text">Catalogs</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCatalogs"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listCatalogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Catalogs</span>
                        </NavLink>
                      </li>
                      {user.role === "dataentry_user" ? (
                        <li className={`menu-item  ${getMenuItemActive(
                          "/addCatalog"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/addCatalog"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Add Catalog</span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link  menu-toggle" to="/Categories">
                    <i className="sbi-gf gf-category menu-icon"></i>
                    <span className="menu-text">Categories</span>
                    <i className="menu-arrow"></i>
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCategories"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/listCategories"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Categories</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/addCategory"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/addCategory"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add Category</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listApprovalCategories"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listApprovalCategories"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Approval Categories</span>
                        </NavLink>
                      </li>

                      {user.role === "dataentry_user" ? (
                        <>
                          <li className={`menu-item  ${getMenuItemActive(
                            "/L1Categories"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/L1Categories"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List L1 Categories</span>
                            </NavLink>
                          </li>

                          {/* <li className={`menu-item  ${getMenuItemActive(
                            "/L2Categories"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/L2Categories"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List L2 Categories</span>
                            </NavLink>
                          </li> */}

                          <li className={`menu-item  ${getMenuItemActive(
                            "/updateBulkCategory"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/updateBulkCategory"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Update Bulk Category</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/listBulkUpdateCategories"
                          )}`}>
                            <NavLink
                              className="menu-link "
                              to="/listBulkUpdateCategories"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Updated Category Files</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      {user.role === "seomanager_user" ? (
                        <>
                          <li className={`menu-item  ${getMenuItemActive(
                            "/bulkCategory"
                          )}`}>
                            <NavLink
                              className="menu-link"
                              to="/bulkCategory"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Upload Bulk Categories</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/listBulkCategories"
                          )}`}>
                            <NavLink
                              className="menu-link"
                              to="/listBulkCategories"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Bulk Category files</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/uploadCategoryRelatedLinks"
                          )}`}>
                            <NavLink
                              className="menu-link"
                              to="/uploadCategoryRelatedLinks"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Upload Related links</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/listCategoryRelatedLinks"
                          )}`}>
                            <NavLink
                              className="menu-link"
                              to="/listCategoryRelatedLinks"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Related Links</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        ""
                      )}

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadCategoryCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadCategoryCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Vendors">
                    <i className="sbi-gf gf-construction menu-icon"></i>
                    <span className="menu-text">Vendors</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listUsers"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listUsers"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Vendors</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item ${getMenuItemActive(
                        "/listDbUsers"
                      )}`}>
                        {user.role === "dataentry_user" ? (
                          <NavLink
                            className="menu-link "
                            to="/listDbUsers"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List DB Vendors</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadUserCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadUserCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/recentUsersList"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/recentUsersList"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Recent Vendors</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listDeletedUsers"
                      )}`}>
                        {(user.role === "dataentry_user") ? (
                          <NavLink
                            className="menu-link "
                            to="/listDeletedUsers"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List Deleted Users</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li>

                      {user.role === "seomanager_user" ? (
                        <>
                          <li className={`menu-item  ${getMenuItemActive(
                            "/listUserRelatedLinks"
                          )}`}>
                            <NavLink
                              className="menu-link"
                              to="/listUserRelatedLinks"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">List Related Links</span>
                            </NavLink>
                          </li>

                          <li className={`menu-item  ${getMenuItemActive(
                            "/uploadUserRelatedLinks"
                          )}`}>
                            <NavLink
                              className="menu-link"
                              to="/uploadUserRelatedLinks"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Upload Related Links</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Contacts">
                    <i className="fas fa-address-card menu-icon"></i>
                    <span className="menu-text">Contacts</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listContactGroups"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listContactGroups"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Contact Groups</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listContactRequest"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listContactRequest"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Contact Request</span>
                        </NavLink>
                      </li>

                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/VendorListing">
                    <i className="fas fa-copy menu-icon"></i>
                    <span className="menu-text">Vendors Listing</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">

                      {(user.role === "admin" || user.role === "seomanager_user" || user.role === "readOnly_user") ? (
                        <li className={`menu-item ${getMenuItemActive(
                          "/addVendorsDesc"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/addVendorsDesc"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Add User and Desc</span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      )}
                      <li className={`menu-item ${getMenuItemActive(
                        "/listVendors"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listVendors"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Vendors</span>
                        </NavLink>
                      </li>


                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadVendorListingCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadVendorListingCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>

                    </ul>
                  </div>
                </li>

                {(user.role === "dataentry_user") ? (
                  <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                    <NavLink className="menu-link menu-toggle" to="/qaVendors">
                      <i className="fas fa-bus menu-icon"></i>
                      <span className="menu-text">QA Vendors</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu">
                      <ul className="menu-subnav">

                        <li className={`menu-item ${getMenuItemActive(
                          "/listQaVendors"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/listQaVendors"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">List QA Vensors</span>
                          </NavLink>
                        </li>

                      </ul>
                    </div>
                  </li>
                ) : (
                  ""
                )}

                <li className={`menu-item menu-item-submenu`}>
                  <NavLink className="menu-link menu-toggle" to="/Products">
                    <i className="fas fa-sort-amount-down-alt menu-icon"> </i>
                    <span className="menu-text">Product Orders</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">

                      {/* Product Inquiries Start */}
                      {user.role === "dataentry_user" ? (
                        <li className={`menu-item ${getMenuItemActive(
                          "/listProductInquiries"
                        )}`}>
                          <NavLink
                            className="menu-link "
                            to="/listProductInquiries"
                          >
                            {/* <i className="fas fa-table menu-icon"></i> */}
                            <span className="menu-text">List Product Inquiries</span>
                          </NavLink>
                        </li>
                      ) : (
                        ""
                      )}
                      {/* Product Inquiries End*/}

                      {/* Product Quotation Start */}
                      {/* <li className={`menu-item ${getMenuItemActive(
                        "/listQuotations"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listQuotations"
                        >
                          <i className="fab fa-accusoft menu-icon"></i>
                          <span className="menu-text">List Quotations</span>
                        </NavLink>
                      </li> */}

                      <li className={`menu-item ${getMenuItemActive(
                        "/listRfqQuotations"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listRfqQuotations"
                        >
                          {/* <i className="fab fa-accusoft menu-icon"></i> */}
                          <span className="menu-text">List Rfq Quotations</span>
                        </NavLink>
                      </li>
                      {/* Product Quotaion End*/}

                      {/* <li className={`menu-item ${getMenuItemActive(
                        "/listESRfqs"
                      )}`}>
                        {user.role === "dataentry_user" ? (
                          <NavLink
                            className="menu-link "
                            to="/listESRfqs"
                          >
                            <i className="fab fa-accusoft menu-icon"></i>
                            <span className="menu-text">List ES Rfq Quotations</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li> */}

                      {/* Product Order Start */}
                      <li className={`menu-item ${getMenuItemActive(
                        "/productOrder"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/productOrder"
                        >
                          {/* <i className="fas fa-sort-amount-down-alt menu-icon"> </i> */}
                          <span className="menu-text">List Product Orders</span>
                        </NavLink>
                      </li>
                      {/* Product Order End*/}

                      {/* <li className={`menu-item ${getMenuItemActive(
                        "/listESOrders"
                      )}`}>
                        {user.role === "dataentry_user" ? (
                          <NavLink
                            className="menu-link "
                            to="/listESOrders"
                          >
                            <i className="fas fa-sort-amount-down-alt menu-icon"> </i>
                            <span className="menu-text">List ES Product Orders</span>
                          </NavLink>
                        ) : (
                          ""
                        )}
                      </li> */}

                    </ul>
                  </div>
                </li>

                {/* SsoUser Start */}
                {(user.role === "admin_readOnly" || user.role === "readOnly_user") ? (
                  <li className={`menu-item ${getMenuItemActive(
                    "/listSsoUser"
                  )}`}>
                    <NavLink
                      className="menu-link "
                      to="/listSsoUser"
                    >
                      <i className="fas fa-user menu-icon"> </i>
                      <span className="menu-text">List SSO Users</span>
                    </NavLink>
                  </li>
                ) : (
                  ""
                )}
                {/* SsoUser End*/}

                {/* Post Start */}
                <li className={`menu-item ${getMenuItemActive(
                  "/listPost"
                )}`}>
                  {user.role !== "dataentry_user" ? (
                    <NavLink
                      className="menu-link "
                      to="/listPost"
                    >
                      <i className="fas fa-sign menu-icon"> </i>
                      <span className="menu-text">List Post</span>
                    </NavLink>
                  ) : (
                    ""
                  )}
                </li>
                {/* Post End*/}

              </>
            ) : (
              ""
            )}

            {(user.role === "admin" || user.role === "dataentry_user") ?
              <>
                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link  menu-toggle" to="/Logs">
                    <i className="sbi-gf gf-pages menu-icon"></i>
                    <span className="menu-text">Logs Listing</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">

                      <li className={`menu-item  ${getMenuItemActive(
                        "/ListMopLogs"
                      )}`}>
                        <NavLink
                          className="menu-link sub-nav"
                          to="/ListMopLogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Mop Logs</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/ListApiErrLogs"
                      )}`}>
                        <NavLink
                          className="menu-link sub-nav"
                          to="/ListApiErrLogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Scripts Err Logs</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/ListSubsLogs"
                      )}`}>
                        <NavLink
                          className="menu-link sub-nav"
                          to="/ListSubsLogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Subscription Logs</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/ListShopifyConnLogs"
                      )}`}>
                        <NavLink
                          className="menu-link sub-nav"
                          to="/ListShopifyConnLogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Shopify Conn Logs</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/ListProdSyncLogs"
                      )}`}>
                        <NavLink
                          className="menu-link sub-nav"
                          to="/ListProdSyncLogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Shopify Prod Sync Logs</span>
                        </NavLink>
                      </li>

                    </ul>
                  </div>
                </li>

                {/* SsoUser Start */}
                <li className={`menu-item ${getMenuItemActive(
                  "/listSsoUser"
                )}`}>
                  <NavLink
                    className="menu-link "
                    to="/listSsoUser"
                  >
                    <i className="fas fa-user menu-icon"> </i>
                    <span className="menu-text">List SSO Users</span>
                  </NavLink>
                </li>
                {/* SsoUser End*/}

                {/* Post Start */}
                <li className={`menu-item ${getMenuItemActive(
                  "/listPost"
                )}`}>
                  <NavLink
                    className="menu-link "
                    to="/listPost"
                  >
                    <i className="fas fa-sign menu-icon"> </i>
                    <span className="menu-text">List Post</span>
                  </NavLink>
                </li>
                {/* Post End*/}
              </>
              : ""
            }

            {(user.role === "admin" || user.role === "teamlead" || user.role === "admin_readOnly" || user.role === "seomanager_user") ?
              <>
                {mTags(getMenuItemActive)}
                <li className={`menu-item  ${getMenuItemActive(
                  "/businessInfo"
                )}`}>
                  <NavLink
                    className="menu-link"
                    to="/businessInfo"
                  >
                    <i className="fas fa-info menu-icon"></i>
                    <span className="menu-text">Business Info </span>
                  </NavLink>
                </li>
              </>
              : ""
            }
            

            {(user.role === "admin" || user.role === "dataentry_user") ?
              <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/Invitation">
                  <i className="fa fa-paper-plane menu-icon"></i>
                  <span className="menu-text">Send Invitation</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu">
                  <ul className="menu-subnav">
                    <li className={`menu-item  ${getMenuItemActive(
                      "/inviteCustomer"
                    )}`}>
                      <NavLink
                        className="menu-link"
                        to="/inviteCustomer"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                            <span />
                        </i>
                        <span className="menu-text">Invite Customer </span>
                      </NavLink>
                    </li>

                    
                    <li className={`menu-item  ${getMenuItemActive(
                        "/ListInvitedCustomers"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/ListInvitedCustomers"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Invited Customers </span>
                        </NavLink>
                      </li>
                  </ul>
                </div>
              </li>
              : ""
            }

            {(user.role === "helpdesk_user" || user.role === "admin" || user.role === "teamlead") ? (
              <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                <NavLink className="menu-link  menu-toggle" to="/Help">
                  <i className="fas fa-question menu-icon"></i>
                  <span className="menu-text">Help</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li className={`menu-item  ${getMenuItemActive(
                      "/articles"
                    )}`}>
                      <NavLink
                        className="menu-link sub-nav"
                        to="/articles"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Add Article</span>
                      </NavLink>
                    </li>
                    {/* /addContent */}
                    <li className={`menu-item  ${getMenuItemActive(
                      "/addcontent"
                    )}`}>
                      <NavLink
                        className="menu-link sub-nav"
                        to="/addcontent"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Add Content</span>
                      </NavLink>
                    </li>

                    {/* /contents */}
                    <li className={`menu-item  ${getMenuItemActive(
                      "/contents"
                    )}`}>
                      <NavLink
                        className="menu-link sub-nav"
                        to="/contents"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">List All Content</span>
                      </NavLink>
                    </li>

                    {/* /findAnswers */}
                    <li className={`menu-item  ${getMenuItemActive(
                      "/findAnswers"
                    )}`}>
                      <NavLink
                        className="menu-link sub-nav"
                        to="/findAnswers"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Submitted Answers</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}


            {(user.role === "basic_user") ? (
              <>
                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/VendorListing">
                    <i className="fas fa-copy menu-icon"></i>
                    <span className="menu-text">Vendors Listing</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">

                      <li className={`menu-item ${getMenuItemActive(
                        "/addVendorsDesc"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/addVendorsDesc"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add User and Desc</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item ${getMenuItemActive(
                        "/listVendors"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listVendors"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Vendors</span>
                        </NavLink>
                      </li>


                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadVendorListingCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadVendorListingCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>

                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link  menu-toggle" to="/Categories">
                    <i className="sbi-gf gf-category menu-icon"></i>
                    <span className="menu-text">Categories</span>
                    <i className="menu-arrow"></i>
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCategories"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/listCategories"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Categories</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/addCategory"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/addCategory"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add Category</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listApprovalCategories"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listApprovalCategories"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Approval Categories</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listdownloadCategoryCsv"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listdownloadCategoryCsv"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Download Csv File</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              </>
              // <li className="menu-item menu-item-submenu" data-menu-toggle="hover"
              //   aria-haspopup="true"
              // >
              //   <NavLink className="menu-link menu-toggle" to="/bulkProducts">
              //     <i className="fas fa-boxes menu-icon" ></i>
              //     <span className="menu-text">Product Bulk</span>
              //     <i className="menu-arrow" />
              //   </NavLink>
              //   <div className="menu-submenu">
              //     <ul className="menu-subnav">

              //       <li className={`menu-item  ${getMenuItemActive(
              //         "/uploadproduct"
              //       )}`}>
              //         <NavLink
              //           className="menu-link "
              //           to="/uploadproduct"
              //         >
              //           <i className="menu-bullet menu-bullet-dot">
              //             <span />
              //           </i>
              //           <span className="menu-text">Upload Bulk file</span>
              //         </NavLink>
              //       </li>

              //       <li className={`menu-item  ${getMenuItemActive(
              //         "/uplodedFiles"
              //       )}`}>
              //         <NavLink
              //           className="menu-link "
              //           to="/uplodedFiles"
              //         >
              //           <i className="menu-bullet menu-bullet-dot">
              //             <span />
              //           </i>
              //           <span className="menu-text">Uploaded Files Status</span>
              //         </NavLink>
              //       </li>

              //     </ul>
              //   </div>
              // </li>
            ) : (
              ""
            )}

            {(user.role === "sem_user") ? (
              <>
                {mTags(getMenuItemActive)}

                <li className={`menu-item  ${getMenuItemActive(
                  "/businessInfo"
                )}`}>
                  <NavLink
                    className="menu-link"
                    to="/businessInfo"
                  >

                    <i className="fas fa-info menu-icon"></i>
                    <span className="menu-text">Business Info </span>
                  </NavLink>
                </li>
                <li className={`menu-item menu-item-submenu`}>
                  <NavLink className="menu-link menu-toggle" to="/Categories">

                    <i className="sbi-gf gf-category menu-icon"></i>
                    <span className="menu-text">Categories</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCategories"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/listCategories"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Categories</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className={`menu-item menu-item-submenu`}>
                  <NavLink className="menu-link menu-toggle" to="/Products">
                    <i className="fas fa-box menu-icon" ></i>
                    <span className="menu-text">Products</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listProducts"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listProducts"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Products</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

              </>
            ) : (
              ""
            )}

            {user.role === "admin" || user.role === "teamlead" || user.role === "dataentry_user" ? (
              <>
                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link  menu-toggle" to="/Others">
                    <i className="sbi-gf gf-dynamic-feed menu-icon"></i>
                    <span className="menu-text">Other Pages</span>
                    <i className="menu-arrow"></i>
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">

                      <li className={`menu-item  ${getMenuItemActive(
                        "/staticPages"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/staticPages"
                        >

                          {/* <i className="sbi-gf gf-insert-drive-file menu-icon"></i> */}
                          <span className="menu-text">Static Pages</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/listFeatureProducts"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/listFeatureProducts"
                        >

                          {/* <i className="sbi-gf gf-pages menu-icon"></i> */}
                          <span className="menu-text">List Feature Products</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/bannerProducts"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/bannerProducts"
                        >

                          {/* <i className="sbi-gf gf-dynamic-feed menu-icon"></i> */}
                          <span className="menu-text">List Banner</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/newArrivalProducts"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/newArrivalProducts"
                        >

                          {/* <i className="fas fa-parachute-box menu-icon"></i> */}
                          <span className="menu-text">List New Arrivals</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/topCompanies"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/topCompanies"
                        >

                          {/* <i className="sbi-gf gf-anchor menu-icon"></i> */}
                          <span className="menu-text">Top Companies</span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/newestMembers"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/newestMembers"
                        >

                          {/* <i className="fas fa-boxes menu-icon" ></i> */}
                          <span className="menu-text">Whole Sale Members</span>
                        </NavLink>
                      </li>

                    </ul>
                  </div>
                </li>
              </>
            ) : (
              ""
            )}


            {user.role === "seo_user" ? (
              <>
                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Vendors">
                    <i className="sbi-gf gf-construction menu-icon"></i>
                    <span className="menu-text">Vendors</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listUsers"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/listUsers"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Vendors</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Invitation">
                    <i className="fa fa-paper-plane menu-icon"></i>
                    <span className="menu-text">Send Invitation</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/inviteCustomer"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/inviteCustomer"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Invite Customer </span>
                        </NavLink>
                      </li>

                      <li className={`menu-item  ${getMenuItemActive(
                        "/ListInvitedCustomers"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/ListInvitedCustomers"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Invited Customers </span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

                <li className={`menu-item ${getMenuItemActive(
                  "/listSsoUser"
                )}`}>
                  <NavLink
                    className="menu-link "
                    to="/listSsoUser"
                  >
                    <i className="fas fa-user menu-icon"> </i>
                    <span className="menu-text">List SSO Users</span>
                  </NavLink>
                </li>
              </>
            ) : (
              ""
            )}


            {user.role === "seo_intern" ? (
              <li className="menu-item menu-item-submenu" data-menu-toggle="hover"
                aria-haspopup="true"
              >
                <NavLink className="menu-link menu-toggle" to="/Products">
                  <i className="fas fa-box menu-icon" ></i>
                  <span className="menu-text">Products</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu">
                  <ul className="menu-subnav">

                    <li className={`menu-item ${getMenuItemActive(
                      "/listProducts"
                    )}`}>
                      <NavLink
                        className="menu-link "
                        to="/listProducts"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">List Products</span>
                      </NavLink>
                    </li>

                    <li className={`menu-item  ${getMenuItemActive(
                      "/listdownloadCsv"
                    )}`}>
                      <NavLink
                        className="menu-link "
                        to="/listdownloadCsv"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Download Csv File</span>
                      </NavLink>
                    </li>

                  </ul>
                </div>
              </li>
            ) : (
              ""
            )}

            
            {/* {(user.role === "dataentry_user1") ? (
              <>
                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Products">
                    <i className="fas fa-box menu-icon" ></i>
                    <span className="menu-text">Products</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listProducts"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/listProducts"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Products</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/uploadproduct"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/uploadproduct"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Upload Bulk file</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/uplodedFiles"
                      )}`}>
                        <NavLink
                          className="menu-link "
                          to="/uplodedFiles"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Bulk Uploaded Product</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>


                <li className="menu-item menu-item-submenu" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/Catalogs">
                    <i className="fas fa-sitemap menu-icon"></i>
                    <span className="menu-text">Catalogs</span>
                    <i className="menu-arrow" />

                  </NavLink>
                  <div className="menu-submenu">
                    <ul className="menu-subnav">
                      <li className={`menu-item  ${getMenuItemActive(
                        "/listCatalogs"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/listCatalogs"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">List Catalogs</span>
                        </NavLink>
                      </li>
                      <li className={`menu-item  ${getMenuItemActive(
                        "/addCatalog"
                      )}`}>
                        <NavLink
                          className="menu-link"
                          to="/addCatalog"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add Catalog</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>

              </>
            ) : (
              ""
            )} */}

          </>
        ) : (
          ""
        )}
        {/*end::1 Level*/}
        {/** END HERE */}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
