import React from 'react';
import axios from 'axios';
import setAuthToken from "../../../utils/setAuthToken";
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { connect } from "react-redux";
import overlayFactory from 'react-bootstrap-table2-overlay';
import paginationFactory, { PaginationProvider, PaginationListStandalone, PaginationTotalStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
// import { Link } from 'react-router-dom';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';



const dateFormater = (cell, row) => (
    <span>{new Date(cell).toUTCString('en-US', { year: "numeric", month: "short", day: "numeric", hour: '2-digit', minute: '2-digit', second: '2-digit' })}</span>
)


const unameFormatter = (cell, row) => (
    row.send_by && row.send_by.name ?
        <span>{row.send_by.name}</span>
        // <div style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }} title='Link'>
        //     <Link
        //         // className="menu-link"
        //         to={{ pathname: `./vendorView`, search: `?userId=${row.send_by._id}` }}
        //     >
        //         {row.send_by.name}
        //     </Link>
        // </div>
        : ''
);

let emailFilter;
let typeFilter;

const columns = [

    {
        dataField: 'email',
        text: 'Email ',
        filter: textFilter({
            getFilter: (filter) => {
                emailFilter = filter;
            },
            delay: 2000
        }),
        headerStyle: {
            fontWeight: 'bold',
            width: '8rem',
            textAlign: 'center'
        }
    },

    {
        dataField: 'package_name',
        text: 'Package Name',
        headerStyle: {
            fontWeight: 'bold',
            width: '6rem',
            textAlign: 'center'
        }
    },

    {
        dataField: 'send_by.name',
        text: 'Send By ',
        formatter: unameFormatter,
        headerStyle: {
            fontWeight: 'bold',
            width: '6rem',
            textAlign: 'center'
        }
    },

    {
        dataField: 'status',
        text: 'Status ',
        align: 'center',
        filter: selectFilter({
            getFilter: (filter) => {
                typeFilter = filter;
            },
            options: { 0: 'True', 1: 'False', 2: 'All' }
        }),
        headerStyle: {
            fontWeight: 'bold',
            width: '5rem',
            textAlign: 'center'
        }
    },

    {
        dataField: 'createdAt',
        text: 'Created At',
        sort: true,
        formatter: dateFormater,
        headerStyle: {
            fontWeight: 'bold',
            width: '6rem',
            textAlign: "center",
        },
    },

];

const changeFilters = (props) => {
    // console.log('props ', props)
    props.map((item) => {
        if (item.email) {
            emailFilter(item.email);
        } else if (item.groupType) {
            typeFilter(item.groupType);
        }
        return item
    })
};

const clearFilter = () => {
    localStorage.setItem('emptyFilter', false);
    emailFilter('');
    localStorage.setItem('emptyFilter', true);
    typeFilter();
}

const defaultSorted = [{
    dataField: 'createdAt',
    order: 'desc'
}];

const NoDataIndication = ({ loading, totalSize }) => (
    <div>
        {loading && totalSize === 0 ?
            <div className="spinner-grow align-center" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            :
            <div>No record found</div>
        }
    </div>
);

const RemotePagination = ({ data, page, sizePerPage, onTableChange, totalSize, selectRow, loading, options }) => (

    <>
        <PaginationProvider
            pagination={
                paginationFactory({
                    custom: true,
                    page,
                    sizePerPage,
                    totalSize,
                    showTotal: true,
                    // sizePerPageOptionRenderer,
                    sizePerPageList: [
                        {
                            text: '10', value: 10
                        }, {
                            text: '20', value: 20
                        }, {
                            text: '30', value: 30
                        }, {
                            text: '50', value: 50
                        }, {
                            text: '100', value: 100
                        }, {
                            text: '200', value: 200
                        }]
                })
            }
        >
            {
                ({
                    paginationTableProps,
                    paginationProps
                }) => (
                    <>
                        <BootstrapTable
                            bootstrap4
                            remote
                            loading={loading}
                            keyField="_id"
                            data={data}
                            columns={columns}
                            defaultSorted={defaultSorted}
                            filter={filterFactory()}
                            {...paginationTableProps}
                            onTableChange={onTableChange}
                            selectRow={selectRow}
                            overlay={overlayFactory({ spinner: true, background: 'rgba(192,192,192,0.3)' })}
                            noDataIndication={(loading, totalSize) => <NoDataIndication />}
                            hover
                            condensed
                        />
                        <div className="row react-bootstrap-table-pagination mt-4">
                            <div className="col-md-1 col-xs-1 col-sm-1 col-lg-1" style={{}}>
                                <SizePerPageDropdownStandalone className="pageSizer" {...paginationProps} />
                            </div>
                            <div className="col-md-5 col-xs-5 col-sm-5 col-lg-5" style={{ marginTop: "13px" }}>
                                <PaginationTotalStandalone {...paginationProps} />
                            </div>
                            <div className="react-bootstrap-table-pagination-list col-md-6 col-xs-6 col-sm-6 col-lg-6">
                                <PaginationListStandalone {...paginationProps} />
                            </div>
                        </div>
                    </>
                )
            }
        </PaginationProvider>
    </>
);

class ListInvitedUsers extends React.Component {
    constructor(props) {

        if (props.location.search) {
            var pageNo = Number(new URLSearchParams(props.location.search).get('page'))
            var size = Number(new URLSearchParams(props.location.search).get('sizePerPage'))
            var dateFilter = JSON.parse(new URLSearchParams(props.location.search).get('date'))
        }

        super(props);
        // console.log(props.type);
        this.state = {
            page: pageNo || 1,
            data: [],
            totalSize: 0,
            sizePerPage: size || 10,
            selected: [],
            loading: true,
            defaultSorted: {},
            filters: {},
            sortField: '',
            sortOrder: '',
            catalogsList: [],
            usersList: [],
            userId: '',
            catalogId: '',
            alert: {},
            timeOut: null,
            date: dateFilter || []
        };
        this.handleTableChange = this.handleTableChange.bind(this);
        this.handleOnSelect = this.handleOnSelect.bind(this);

    }

    componentDidMount() {
        var data = []
        var filterVal = JSON.parse(new URLSearchParams(this.props.location.search).get('filters'))
        if (filterVal) {
            Object.entries(filterVal).map(([key, value]) => {
                var filters = value.filterVal
                data.push({ [key]: filters })
                return key
            })
        }

        changeFilters(data)
    }

    componentWillUnmount() {
        this.state.timeOut && clearTimeout(this.state.timeOut);
    }

    static getDerivedStateFromProps(props, state) {
        let { email = '' } = props.userDetail || {};
        let { _email = '' } = state.userData || {};
        if (email !== _email) {
            return {
                userData: props.userDetail
            };
        }
        return null;
    }

    // this prevent extra rerender because of getDerivedStateFromProps
    componentDidUpdate(prevProps, prevState) {
        if (this.props.userDetail.email !== prevProps.userDetail.email) {
            this.selectNew();
        }
    }

    handleOnSelect = (row, isSelect) => {
        if (row.isTrashed) {
            alert('Can`t perform any Action on Trashed Products');
            return this.state.selected;
        }
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row._id]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row._id)
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const idss = rows.filter((r) => !r.isTrashed);
        let ids = idss.map((r) => r._id);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
            ids = [];
        }
        return ids;
    }


    handleTableChange = (type, { page, sizePerPage, filters, sortField, sortOrder }) => {

        if (localStorage.token) {
            setAuthToken(localStorage.token);
        }
        var pageNo = page

        var urlfilters = JSON.parse(new URLSearchParams(this.props.location.search).get('filters'))
        var pageNo2 = Number(new URLSearchParams(this.props.location.search).get('page'))

        var emptyValue = localStorage.getItem("emptyFilter");
        if (this.props.history.action === 'POP') {
            localStorage.setItem('emptyFilter', true);
        } else if (emptyValue === 'false') {
            return;
        }

        if (this.props.history.action === 'POP' && urlfilters !== null && JSON.stringify(filters) !== JSON.stringify(urlfilters)) {
            return;
        } else if (this.props.history.action === 'POP' && JSON.stringify(filters) === JSON.stringify(urlfilters)) {
            pageNo = pageNo2
        }

        const { date } = this.state;
        const body = { pageNo, sizePerPage, filters, sortField, sortOrder, date };

        // axios.post('/api/connect/contactGroupListing', body)
        axios.post('/api/users/invitedCustomerListing', body)
            .then(res => {
                // console.log("res  ", res.data.data);
                const data = res.data.data || [];
                const { userData = {} } = this.state;
                for (const itr of data) {
                    itr.role = userData.role || '';
                }
                this.setState({
                    page: res.data.page,
                    data: data,
                    totalSize: res.data.recordsTotal,
                    sizePerPage: sizePerPage,
                    loading: false,
                    defaultSorted: defaultSorted,
                    filters: filters,
                    sortField: sortField,
                    sortOrder: sortOrder,
                    selected: [],
                })

                let pathname = this.props.location.pathname;

                let SearchParams = new URLSearchParams(this.props.location.search);
                SearchParams.set('page', res.data.page);
                SearchParams.set('sizePerPage', res.data.sizePerPage);
                SearchParams.set('filters', JSON.stringify(filters));
                SearchParams.set('date', JSON.stringify(res.data.dateFilter));
                let path = window.location.pathname;

                if (path.toString() === pathname.toString()) {
                    this.props.history.replace({
                        pathname: pathname,
                        search: SearchParams.toString()
                    });
                }

            }).catch(e => console.log(e));
        this.setState({ loading: true, data: [] });
    }

    changeValue = (e) => {
        var value = [];
        if (e !== '' && e !== null) {
            if (e[0] !== '' && e[1] !== '') {
                let startDate = moment.utc(e[0], 'YYYY-MM-DD hh:mm A').add(5, 'hours').toJSON();
                value.push(startDate);
                let endDate = moment.utc(e[1], 'YYYY-MM-DD hh:mm A').add(5, 'hours').toJSON();
                value.push(endDate);
            }
        }
        this.setState({ date: value }, () => {
            this.handleTableChange('sort', this.state);
        });
    }

    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            style: { backgroundColor: '#c8e6c9', align: 'middle' },
            selected: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };

        const { data, sizePerPage, totalSize, page, loading, alert = {} } = this.state;//, stats

        return (
            <div className="container-fluid">
                {alert.msg &&
                    <div className="d-flex justify-content-center">
                        <div className={`alert alert-${alert.alertType}`} style={{ zIndex: '9' }} role="alert">
                            {alert.msg}
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-custom gutter-b">
                            <div className="card-header border-0 pt-4">
                                <div className="card-title align-items-start flex-column">
                                    <h3 className="text-primary">
                                        Invited Customers
                                    </h3>
                                    <h5 className="mt-0 font-size-sm text-muted">
                                        Listing of Invited Customers
                                    </h5>
                                </div>
                            </div>
                            <div className="card-body" style={{ padding: '0.9375rem 1.875rem' }}>

                                <div className="form-row">
                                    <div className="form-group col-md-4">
                                        <label><b>Select Date :</b></label>
                                        <div className="field" >
                                            <DateRangePicker format="yyyy-MM-dd hh:mm aa" showMeridian onChange={this.changeValue}
                                                value={this.state.date[0] ? [new Date(this.state.date[0].toString()), new Date(this.state.date[1].toString())] : []} />
                                        </div>
                                    </div>
                                </div>
                                {Object.keys(this.state.filters).length > 0 ?
                                    <button className="btn btn-md btn-primary float-left mb-2" onClick={clearFilter}> Clear all filters</button>
                                    :
                                    <button className="btn btn-md btn-primary float-left mb-2" style={{ cursor: 'not-allowed' }} disabled> Clear all filters</button>
                                }

                                <div className="table-responsive" style={{ overflowX: "hidden", minHeight: "260px", padding: "4px", margin: "0" }}>
                                    <RemotePagination
                                        data={data}
                                        page={page}
                                        sizePerPage={sizePerPage}
                                        totalSize={totalSize}
                                        onTableChange={this.handleTableChange}
                                        selectRow={selectRow}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userDetail: state.auth.user,
    };
};

export default connect(mapStateToProps, {})(ListInvitedUsers);