
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../_seebizsol/_partials/controls";
import { useSelector, shallowEqual } from "react-redux";
import axios from "axios";
import { useSubheader } from "../../_seebizsol/layout";


function ChangePassword() {
    // Fields
    const [loading, setloading] = useState(false);
    const [alert, setAlert] = useState('');
    const [error, setError] = useState(false);

    const subheader = useSubheader();
    useEffect(() => { subheader.setTitle("Change Your Password"); })

    const user = useSelector((state) => state.auth.user, shallowEqual);
    useEffect(() => { }, [user]);


    async function changePassword(updatedUser) {
        try {
            const res = await axios.put('/api/users/changePassword', updatedUser)
            if (res.status === 200) {
                setAlert(res.data.message)
            } else {
                console.log(res.message);
                setError(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const saveUser = (values, setStatus, setSubmitting) => {
        setloading(true);

        const updatedUser = Object.assign(values, {
            email: user.email,
        });
        changePassword(updatedUser); 

        setTimeout(() => {
            setloading(false);
            setSubmitting(false);
            setAlert('');
            setError(false);
        }, 2000);
    };
    // UI Helpers
    const initialValues = {
        currentPassword: "",
        password: "",
        cPassword: "",
    };

    const Schema = Yup.object().shape({
        currentPassword: Yup.string().required("Current password is required"),
        password: Yup.string().required("New Password is required"),
        cPassword: Yup.string()
            .required("Password confirmation is required")
            .when("password", {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Password and Confirm Password didn't match"
                ),
            }),
    });

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }
        // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        //   return "is-valid";
        // }
        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            saveUser(values, setStatus, setSubmitting);
        },
        onReset: (values, { resetForm }) => {
            resetForm();
        },
    });

    return (
        <div className="container">
            <form className="card card-custom" onSubmit={formik.handleSubmit}>
                {loading && <ModalProgressBar />}

                {/* begin::Header */}
                <div className="card-header py-3">
                    <div className="card-title align-items-start flex-column">
                        <h3 className="text-primary">
                            Change Password
                        </h3>
                        <h5 className="mt-0 font-size-sm text-muted">
                            Change Admin User account password
                        </h5>
                    </div>
                    <div className="card-toolbar">
                        <button
                            type="submit"
                            className="btn btn-success mr-2"
                            disabled={
                                formik.isSubmitting || (formik.touched && !formik.isValid)
                            }
                        >
                            Save Changes
                            {formik.isSubmitting}
                        </button>
                        <Link
                            to="/listUsers"
                            className="btn btn-secondary"
                        >
                            Cancel
                        </Link>
                    </div>
                </div>
                {/* end::Header */}
                {/* begin::Form */}
                <div className="form">
                    <div className="card-body">

                        {/* begin::Alert */}
                        {alert !== '' && (
                            <div
                                className="alert alert-custom alert-light-primary fade show mb-10"
                                role="alert"
                            >
                                <div className="alert-text font-weight-bold">
                                    {alert}
                                </div>
                                <div className="alert-close" onClick={() => setAlert('')}>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">
                                            <i className="sbi sbi-close"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        )}

                        {error && (
                            <div
                                className="alert alert-custom alert-light-danger fade show mb-10"
                                role="alert"
                            >
                                <div className="alert-text font-weight-bold">
                                    User credentials are invalid!
                                </div>
                                <div className="alert-close" onClick={() => setError(false)}>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">
                                            <i className="sbi sbi-close"></i>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        )}
                        {/* end::Alert */}

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label text-alert" style={{ fontWeight: 'bold' }}>
                                Current Password
                            </label>
                            <div className="col-lg-9 col-xl-6">
                                <input
                                    type="password"
                                    placeholder="Current Password"
                                    className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                                        "currentPassword"
                                    )}`}
                                    name="currentPassword"
                                    {...formik.getFieldProps("currentPassword")}
                                />
                                {formik.touched.currentPassword &&
                                    formik.errors.currentPassword ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.currentPassword}
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                New Password
                            </label>
                            <div className="col-lg-9 col-xl-6">
                                <input
                                    type="password"
                                    placeholder="New Password"
                                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                        "password"
                                    )}`}
                                    name="password"
                                    {...formik.getFieldProps("password")}
                                />
                                {formik.touched.password && formik.errors.password ? (
                                    <div className="invalid-feedback">{formik.errors.password}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-xl-3 col-lg-3 col-form-label" style={{ fontWeight: 'bold' }}>
                                Verify Password
                            </label>
                            <div className="col-lg-9 col-xl-6">
                                <input
                                    type="password"
                                    placeholder="Verify Password"
                                    className={`form-control form-control-lg form-control-solid ${getInputClasses(
                                        "cPassword"
                                    )}`}
                                    name="cPassword"
                                    {...formik.getFieldProps("cPassword")}
                                />
                                {formik.touched.cPassword && formik.errors.cPassword ? (
                                    <div className="invalid-feedback">
                                        {formik.errors.cPassword}
                                    </div>
                                ) : null}
                            </div>
                        </div>

                    </div>
                </div>
                {/* end::Form */}
            </form>
        </div>
    );
}

export default ChangePassword;
