import axios from 'axios';
import store from '../store';

const { dispatch } = store;

export const Request = axios.interceptors.request.use(
  request => {
    if (localStorage.token) {
      request.headers["x-auth-token"] = localStorage.token;
      return request;
    } else if (!localStorage.token && request.url.includes('/users/auth')) {
      return request;
    } else {
      // return alert("please provide token or Login Again");
      window.location.href = "/login";
    }
  }, error => {
    return (error);
  }
);

export const Response = axios.interceptors.response.use(
  response => {
    return response;
  }, error => {
    if (error && (error.response && (error.response.status === 401 && error.response.statusText === "Unauthorized"))) {
      dispatch({ type: "REDIRECT_LOGOUT" });
      // dispatch({ type: "LOGOUT" });
      let loc = `${window.location.origin}/login`
      if (window.location.href === loc) {
        console.log('already redirect')
      } else {
        window.location.href = "/login";
      }
    }
    return error;
  }
);