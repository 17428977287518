import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import CreateUser from "../auth/CreateUser";
import UpdateAdminUser from "../auth/UpdateAdminUser.js";
import Alert from "../layout/Alert";
import Landing from "../layout/Landing";
import Dashboard from "../dashboard/Dashboard";
import ChangePassword from "../dashboard/ChangePassword";
import AddContent from "../dashboard/AddContent";
import FindAllAnswers from "../dashboard/FindAllAnswers";
import ListAllArticles from "../dashboard/ListAllArticles";

import Users from "../dashboard/Users";
import UploadScript from "../dashboard/UploadScript";
import BulkUpdateScript from "../dashboard/product/bulkUpdateScript";
import NotFound from "../layout/NotFound";
import PrivateRoute from "../routing/PrivateRoute";

import StaticPages from '../dashboard/StaticPages';
import MetaTags from "../dashboard/MetaTag";
import BusinessInfo from "../dashboard/BusinessInfo";
import AddCategory from "../dashboard/category/AddCategory";
import AddCatalog from "../dashboard/catalog/AddCatalog";
import EditProduct from "../dashboard/EditProduct";
import ProductEdit from "../dashboard/product/ProductEdit.js";
import DraftEdit from "../dashboard/product/EditDraftProduct.js";
import ProductDetail from "../dashboard/ProductDetail";
import AddCatAndDisc from "../dashboard/vendorListing/addCategAndDesc";
import Spinner from '../layout/Spinner';
import About from '../dashboard/About';
import InviteCustomer from "../dashboard/invitation/inviteCustomer.js";
import ListInvitedCustomer from "../dashboard/invitation/listInvitedUsers.js";
import '../../utils/Interceptor';

const ListAllUsers = lazy(() => import("../dashboard/ListAllUsers") );
const ListElasticUsers = lazy(() => import("../dashboard/user/ListElasticUsers") );
const ListAllProducts = lazy(() => import("../dashboard/ListAllProducts") );
const ListElasticProducts = lazy(() => import("../dashboard/product/ListElasticProducts") );
const ListBulkCatalogs = lazy(() => import("../dashboard/catalog/ListBulkCatalogs") );
const ListBulkCategories = lazy(() => import("../dashboard/category/ListBulkCategories") );
const ListCategoryRelatedLinks = lazy( () => import("../dashboard/category/ListCategoryRelatedLinks") );
const ListAllCatalogs = lazy(() => import("../dashboard/catalog/ListAllCatalogs") );
const UploadedFiles = lazy(() => import("../dashboard/product/UploadedFiles") );
const UpdatedFilesStatus = lazy(() => import("../dashboard/product/UpdatedFilesStatus") );
const ListAllCategories = lazy(() => import("../dashboard/category/ListAllCategories") );
const ListUserRelatedLinks = lazy(() => import("../dashboard/user/ListUserRelatedLinks") );
const ListProductRelatedLinks = lazy(() => import("../dashboard/product/ListProductRelatedLinks") );
const ListAllFeaturedProducts = lazy(()  => import("../dashboard/featured_products/featuredProductsSelection") );
const TopCompany = lazy(() => import('../dashboard/topCompanies/TopCompanyGrid') );
const WholeSaleMemberGrid = lazy(() => import('../dashboard/wholeSaleMembers/WholeSaleMemberGrid') );
const NewArrivals =  lazy(() => import('../dashboard/newArrival_products/NewArrivalSelection') );
const SelectBannerProducts = lazy(() => import("../dashboard/banner/bannerProductsSelection"));
const UserProducts = lazy(() => import("../dashboard/topCompanies/ListUserProducts"));
const ListUserProducts = lazy(() => import("../dashboard/wholeSaleMembers/ListUserProducts"));
const BulkCatalog = lazy(() => import("../dashboard/catalog/BulkCatalog"));
const ListAllContent = lazy(() => import("../dashboard/ListAllContent"));
const UserProfile = lazy(() => import("../dashboard/EditUserProfile"));
const UserProfilePage = lazy(() => import("../dashboard/UserProfile/UserProfilePage"));
const VendorView = lazy(() => import("../dashboard/user/VendorView"));
const BulkCategory = lazy(() => import("../dashboard/category/BulkCategory"));
const EditCategory = lazy(() => import("../dashboard/category/EditCategory"));
const UpdateProductPrice = lazy(() => import("../dashboard/UpdateProductPrice"));
const UploadProductRelatedLinks = lazy(() => import("../dashboard/product/UploadProductRelatedLinks"));
const UploadCategoryRelatedLinks = lazy(() => import("../dashboard/category/UploadCategoryRelatedLinks"));
const UploadUserRelatedLinks = lazy(() => import("../dashboard/user/UploadUserRelatedLinks"));
const UsersList = lazy(() => import("../dashboard/user/UsersList"));
const ListProductInquiries = lazy(() => import("../dashboard/productInquiry/ListAllProductInquiries"));
const ListQuotaions = lazy(() => import("../dashboard/quotation/ListAllQuotations"));
const ViewQuotation = lazy(() => import("../dashboard/quotation/viewQuotation"));
const ListRfqQuotaions = lazy(() => import("../dashboard/quotation/ListRfqQuotations"));
const ListESRfqQuotaions = lazy(() => import("../dashboard/quotation/ListESRfqs"));
const ViewRfqQuotation = lazy(() => import("../dashboard/quotation/viewRfqQuotation"));
const ViewInquiry = lazy(() => import("../dashboard/productInquiry/viewInquiry"));
const ListProductOrders = lazy(() => import("../dashboard/productOrder/listProductOrders"));
const ListEsProductOrders = lazy(() => import("../dashboard/productOrder/listEsProductOrder"));
const ViewOrder = lazy(() => import("../dashboard/productOrder/viewOrder"));
const ListDraftProduct = lazy(() => import("../dashboard/product/listDraftProduct"));
const DraftProductDetail = lazy(() => import("../dashboard/product/draftDetail"));
const AdminUserListing = lazy(() => import("../dashboard/adminUserListing"));
const ListContactGroups = lazy(() => import("../dashboard/contacts/ListContactGroup"));
const ListContactRequest = lazy(() => import("../dashboard/contacts/ListContactRequest"));
const ListAllPost = lazy(() => import("../dashboard/post/ListAllPost"));
const ViewPost = lazy(() => import("../dashboard/post/viewPost"));
const ListVendor = lazy(() => import("../dashboard/vendorListing/listVendor"));
const EditCatAndDisc = lazy(() => import("../dashboard/vendorListing/editCategAndDesc"));
const VendorsDetail = lazy(() => import("../dashboard/vendorListing/vendorDetails/vendorDetails"));
const ListAllSsoUser = lazy(() => import("../dashboard/ssoUser/listAllssoUser"));
const ViewSsoUser = lazy(() => import("../dashboard/ssoUser/viewssoUser"));
const EditSsoUser = lazy(() => import("../dashboard/ssoUser/editssoUser"));
const CronResultListing = lazy(() => import("../dashboard/cronResultListing"));
const SendedEmailListing = lazy(() => import("../dashboard/sendedEmailListing"));
const ListApprovalCategories = lazy(() => import("../dashboard/category/ListApprovalCategories") );
const EditPendingCategory = lazy(() => import("../dashboard/category/EditPendingCategories"));
const ListInActiveUsers = lazy(() => import("../dashboard/ListInActiveUsers") );
const ListProductCsvFiles = lazy(() => import("../dashboard/product/downloadProductCsv") );
const ListStripe = lazy(() => import("../dashboard/ListAllStripes"));
const DeleteBulkUser = lazy(() => import("../dashboard/user/deleteUserByCsv"));
const AddQaVendor = lazy(() => import("../dashboard/AddQaVendors"));
const ListQaVendor = lazy(() => import("../dashboard/ListQaVendors"));
const ListDeletedProduct = lazy(() => import("../dashboard/product/ListDeletedProduct") );
const ListDeletedUsers = lazy(() => import("../dashboard/user/ListDeletedUsers") );
const BulkUpdateCategory = lazy(() => import("../dashboard/category/BulkUpdateCategory"));
const ListBulkUpdateCategories = lazy(() => import("../dashboard/category/ListBulkUpdateCategories") );
const MopLogsListing = lazy(() => import("../dashboard/logsListing/mopLogsListing"));
const ScriptErrLogs = lazy(() => import("../dashboard/logsListing/scriptsErrLogsListing"));
const SubscriptionLogs = lazy(() => import("../dashboard/logsListing/subscriptionLogs"));
const ListL1Categories = lazy(() => import("../dashboard/category/L1Category") );
const ListL1Banners = lazy(() => import("../dashboard/category//banners/L1Banners"));
const AddBanners = lazy(() => import("../dashboard/category//banners/AddBanner"));
const ListL2Categories = lazy(() => import("../dashboard/category/L2Category") );
const UserDetail = lazy(() => import("../dashboard/user/userDetail"));
const ListShopifyProducts = lazy(() => import("../dashboard/shopifyProduct/ListShopifyProducts") );
const ListShopifyProductErrLogs = lazy(() => import("../dashboard/shopifyProduct/ListShopifyProductErrLogs") );
const ListShopifyConnLogs = lazy(() => import("../dashboard/shopifyProduct/ListShopifyConnLog") );
const ListShopifyProdSyncLogs = lazy(() => import("../dashboard/shopifyProduct/ListShopifyProdSyncLog") );

const SignupStats = lazy(() => import("../dashboard/userStats/signupStats"));
const SigninStats = lazy(() => import("../dashboard/userStats/signinStats"));
const PostStats = lazy(() => import("../dashboard/userStats/postStats"));
const ShortListPostStats = lazy(() => import("../dashboard/userStats/shortListPostStats"));
const PostLikesStats = lazy(() => import("../dashboard/userStats/postLikesStats"));
const ProductStats = lazy(() => import("../dashboard/productsStats/productStats"));
const ShortListProductStats = lazy(() => import("../dashboard/productsStats/shortListProductStats"));
const PlacedOrderStats = lazy(() => import("../dashboard/orderStats/placedOrder"));
const ContactRequestStats = lazy(() => import("../dashboard/contactStats/contactRequestStats"));
const ContactInvitStats = lazy(() => import("../dashboard/contactStats/contactInvitStats"));
const ContactCountStats = lazy(() => import("../dashboard/contactStats/contactCountStat"));
const MessageStats = lazy(() => import("../dashboard/contactStats/messageStats"));
const AddProduct = lazy(() => import("../dashboard/product/addProduct"));
const ProductSalesStats = lazy(() => import("../dashboard/salesStats/productSalesStats"));
// const TopSellingProducts = lazy(() => import("../dashboard/salesStats/topSellingProduct"));
const Routes = (_) => {
  return (
    <>
      {" "}
      <Route exact path="/" component={Landing} />
      {/* <div className="content"> */}
        <Alert />
        <Switch>
          <PrivateRoute exact path="/createuser" component={CreateUser} />
          <PrivateRoute exact path="/adminUserProfile" component={UpdateAdminUser} />
          <PrivateRoute exact path="/uploadproduct" component={UploadScript} />
          <PrivateRoute exact path="/bulkUpdateproduct" component={BulkUpdateScript} />
          <PrivateRoute exact path="/edit-product" component={EditProduct} />
          <PrivateRoute exact path="/new-edit" component={ProductEdit} />
          <PrivateRoute exact path="/edit-draft" component={DraftEdit} />
          <PrivateRoute exact path="/product-detail" component={ProductDetail} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/change-password" component={ChangePassword} />
          <PrivateRoute exact path="/addContent" component={AddContent} />
          <PrivateRoute exact path="/findAnswers" component={FindAllAnswers} />
          <PrivateRoute exact path="/articles" component={ListAllArticles} />
          <PrivateRoute exact path="/users" component={Users} />
          <PrivateRoute exact path="/metaTags" component={MetaTags} />
          <PrivateRoute exact path="/businessInfo" component={BusinessInfo} />
          <PrivateRoute exact path="/staticPages" component={StaticPages} />
          <PrivateRoute exact path="/staticPageEditor" component={About} />
          <PrivateRoute exact path="/addCategory" component={AddCategory} />
          <PrivateRoute exact path="/addCatalog" component={AddCatalog} />
          <PrivateRoute exact path="/addVendorsDesc" component={AddCatAndDisc} />
          <Suspense fallback={<Spinner />}>
            <PrivateRoute exact path="/topVendorProducts/:userId" component={UserProducts } />
            <PrivateRoute exact path="/wholeSaleMemberProducts/:userId" component={ListUserProducts } />
            <PrivateRoute exact path="/listBulkCatalog" component={ListBulkCatalogs} />
            <PrivateRoute exact path="/listBulkCategories" component={ListBulkCategories} />
            <PrivateRoute exact path="/listCategoryRelatedLinks" component={ListCategoryRelatedLinks} />
            <PrivateRoute exact path="/listProductRelatedLinks" component={ListProductRelatedLinks} />
            <PrivateRoute exact path="/uplodedFiles" component={UploadedFiles} />
            <PrivateRoute exact path="/updatedFiles" component={UpdatedFilesStatus} />
            <PrivateRoute exact path="/listUserRelatedLinks" component={ListUserRelatedLinks} />
            <PrivateRoute exact path="/listDbProducts" component={ListAllProducts} />
            <PrivateRoute exact path="/listProducts" component={ListElasticProducts} />
            <PrivateRoute exact path="/listCatalogs" component={ListAllCatalogs} />
            <PrivateRoute exact path="/listCategories" component={ListAllCategories} />
            <PrivateRoute exact path="/listDBUsers" component={ListAllUsers} />
            <PrivateRoute exact path="/listUsers" component={ListElasticUsers} />
            <PrivateRoute exact path="/listFeatureProducts" component={ListAllFeaturedProducts} />
            <PrivateRoute exact path="/bannerProducts" component={SelectBannerProducts} />
            <PrivateRoute exact path="/topCompanies" component={TopCompany} />
            <PrivateRoute exact path="/newestMembers" component={WholeSaleMemberGrid} />
            <PrivateRoute exact path="/newArrivalProducts" component={NewArrivals} />
            <PrivateRoute exact path="/bulkCatalog" component={BulkCatalog} />
            <PrivateRoute exact path="/contents" component={ListAllContent} />
            <PrivateRoute exact path="/userprofile" component={UserProfile} />
            <PrivateRoute exact path="/vendorprofile" component={UserProfilePage} />
            <PrivateRoute exact path="/vendorView" component={VendorView} />
            <PrivateRoute exact path="/bulkCategory" component={BulkCategory} />
            <PrivateRoute exact path="/editCategory" component={EditCategory} />
            <PrivateRoute exact path="/updateProductPrice" component={UpdateProductPrice} />
            <PrivateRoute exact path="/uploadProductRelatedLinks" component={UploadProductRelatedLinks} />
            <PrivateRoute exact path="/uploadCategoryRelatedLinks" component={UploadCategoryRelatedLinks} />
            <PrivateRoute exact path="/uploadUserRelatedLinks" component={UploadUserRelatedLinks} />
            <PrivateRoute exact path="/recentUsersList" component={UsersList} />
            <PrivateRoute exact path="/listProductInquiries" component={ListProductInquiries} />
            <PrivateRoute exact path="/listQuotations" component={ListQuotaions} />
            <PrivateRoute exact path="/viewQuotation" component={ViewQuotation} />
            <PrivateRoute exact path="/listRfqQuotations" component={ListRfqQuotaions} />
            <PrivateRoute exact path="/listESRfqs" component={ListESRfqQuotaions} />
            <PrivateRoute exact path="/viewRfqQuotation" component={ViewRfqQuotation} />
            <PrivateRoute exact path="/viewInquiry" component={ViewInquiry} />
            <PrivateRoute exact path="/productOrder" component={ListProductOrders} />
            <PrivateRoute exact path="/listESOrders" component={ListEsProductOrders} />
            <PrivateRoute exact path="/viewOrder" component={ViewOrder} />
            <PrivateRoute exact path="/listDraftProducts" component={ListDraftProduct} />
            <PrivateRoute exact path="/draft-detail" component={DraftProductDetail} />
            <PrivateRoute exact path="/listAdminUsers" component={AdminUserListing} />
            <PrivateRoute exact path="/listContactGroups" component={ListContactGroups} />
            <PrivateRoute exact path="/listContactRequest" component={ListContactRequest} />
            <PrivateRoute exact path="/listPost" component={ListAllPost} />
            <PrivateRoute exact path="/viewPost" component={ViewPost} />
            <PrivateRoute exact path="/listVendors" component={ListVendor} />
            <PrivateRoute exact path="/editVendorsDesc" component={EditCatAndDisc} />
            <PrivateRoute exact path="/vendorsDetail" component={VendorsDetail} />
            <PrivateRoute exact path="/listSsoUser" component={ListAllSsoUser} />
            <PrivateRoute exact path="/viewSsoUser" component={ViewSsoUser} />
            <PrivateRoute exact path="/editSsoUser" component={EditSsoUser} />
            <PrivateRoute exact path="/listCronJobs" component={CronResultListing} />
            <PrivateRoute exact path="/listSendedEmails" component={SendedEmailListing} />
            <PrivateRoute exact path="/listApprovalCategories" component={ListApprovalCategories} />
            <PrivateRoute exact path="/editPendingCategory" component={EditPendingCategory} />
            <PrivateRoute exact path="/listInActiveUsers" component={ListInActiveUsers} />
            <PrivateRoute exact path="/listdownloadCsv" component={ListProductCsvFiles} />
            <PrivateRoute exact path="/listdownloadUserCsv" component={ListProductCsvFiles} />
            <PrivateRoute exact path="/listdownloadVendorListingCsv" component={ListProductCsvFiles} />
            <PrivateRoute exact path="/listdownloadCategoryCsv" component={ListProductCsvFiles} />
            <PrivateRoute exact path="/listStripe" component={ListStripe} />
            <PrivateRoute exact path="/deleteBulkUsers" component={DeleteBulkUser} />
            <PrivateRoute exact path="/addQaVendors" component={AddQaVendor} />
            <PrivateRoute exact path="/listQaVendors" component={ListQaVendor} />
            <PrivateRoute exact path="/listDeletedProducts" component={ListDeletedProduct} />
            <PrivateRoute exact path="/listDeletedUsers" component={ListDeletedUsers} />
            <PrivateRoute exact path="/updateBulkCategory" component={BulkUpdateCategory} />
            <PrivateRoute exact path="/listBulkUpdateCategories" component={ListBulkUpdateCategories} />
            <PrivateRoute exact path="/ListMopLogs" component={MopLogsListing} />
            <PrivateRoute exact path="/ListApiErrLogs" component={ScriptErrLogs} />
            <PrivateRoute exact path="/ListSubsLogs" component={SubscriptionLogs} />
            <PrivateRoute exact path="/L1Categories" component={ListL1Categories} />
            <PrivateRoute exact path="/L1Banners" component={ListL1Banners} />
            <PrivateRoute exact path="/AddBanners" component={AddBanners} />
            <PrivateRoute exact path="/L2Categories" component={ListL2Categories} />
            <PrivateRoute exact path="/userDetail" component={UserDetail} />
            <PrivateRoute exact path="/listShopifyProducts" component={ListShopifyProducts} />
            <PrivateRoute exact path="/shopifyProductErrLogs" component={ListShopifyProductErrLogs} />
            <PrivateRoute exact path="/ListShopifyConnLogs" component={ListShopifyConnLogs} />
            <PrivateRoute exact path="/ListProdSyncLogs" component={ListShopifyProdSyncLogs} />
            <PrivateRoute exact path="/inviteCustomer" component={InviteCustomer} />
            <PrivateRoute exact path="/ListInvitedCustomers" component={ListInvitedCustomer} />

            <PrivateRoute exact path="/signupStats" component={SignupStats} />
            <PrivateRoute exact path="/signinStats" component={SigninStats} />
            <PrivateRoute exact path="/postStats" component={PostStats} />
            <PrivateRoute exact path="/shortListPostStats" component={ShortListPostStats} />
            <PrivateRoute exact path="/postLikesStats" component={PostLikesStats} />
            <PrivateRoute exact path="/productStats" component={ProductStats} />
            <PrivateRoute exact path="/shortListProductStats" component={ShortListProductStats} />
            <PrivateRoute exact path="/placedOrderStats" component={PlacedOrderStats} />
            <PrivateRoute exact path="/contactRequests" component={ContactRequestStats} />
            <PrivateRoute exact path="/contactInvite" component={ContactInvitStats} />
            <PrivateRoute exact path="/contactCounts" component={ContactCountStats} />
            <PrivateRoute exact path="/messageCounts" component={MessageStats} />
            <PrivateRoute exact path="/addProduct" component={AddProduct} />
            <PrivateRoute exact path="/productSalesStats" component={ProductSalesStats} />
            {/* <PrivateRoute exact path="/topSellingProducts" component={TopSellingProducts} /> */}
          </Suspense>
          {/* <PrivateRoute exact path="/api/catalogs/downloadCsv" component={ListBulkCatalogs} /> */}

          {/* <PrivateRoute exact path="/Privacy Policy" component={Privacy} />
          <PrivateRoute exact path="/Terms and Conditions" component={Terms} /> */}
          {/* <PrivateRoute exact path="/help" component={Help} /> */}
          <Route component={NotFound} />
        </Switch>
      {/* </div> */}
    </>
  );
};

export default Routes;
